import axios from "axios";

export const uploadDocument = async (file) => {
  try {
    const formData = new FormData();
    formData.append("upload_preset", "documents_cg");
    formData.append("file", file);

    const url = "https://api.cloudinary.com/v1_1/apitab/image/upload";
    const { data } = await axios.post(url, formData);
    console.log(data);
    return data.secure_url;
  } catch (error) {
    console.log(error);
  }
};

export const uploadDocumentVp = async (file) => {
  try {
    const formData = new FormData();
    formData.append("upload_preset", "documents_vp");
    formData.append("file", file);

    const url = "https://api.cloudinary.com/v1_1/apitab/image/upload";
    const { data } = await axios.post(url, formData);
    console.log(data);
    return data.secure_url;
  } catch (error) {
    console.log(error);
  }
};

export const uploadImage = async (file) => {
  try {
    const formData = new FormData();
    formData.append("upload_preset", "documents_vp"); // Cambia el preset si es necesario
    formData.append("file", file);

    const url = "https://api.cloudinary.com/v1_1/apitab/image/upload"; // Cambia la URL si es necesario
    const { data } = await axios.post(url, formData);
    console.log(data);
    return data.secure_url;
  } catch (error) {
    console.log(error);
    throw new Error("Error al subir la imagen");
  }
};
