<template>
  <Doughnut
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>
<script>
import { Doughnut } from "vue-chartjs/legacy";
import ChartDataLabels from "chartjs-plugin-datalabels";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
} from "chart.js";

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);
ChartJS.register(ChartDataLabels);
ChartJS.defaults.set("plugins.datalabels", {
  color: "#ffffff",
});

export default {
  components: {
    Doughnut,
  },
  props: {
    chartData: {
      type: Object,
      default: "",
    },
    chartId: {
      type: String,
      default: "doughnut-chart",
    },

    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      chartData: {
        labels: ["VueJs", "EmberJs", "ReactJs", "AngularJs"],
        datasets: [
          {
            backgroundColor: ["#41B883", "#E46651", "#00D8FF", "#DD1B16"],
            data: [40, 20, 80, 10],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            labels: {
              color: "white",
              font: {
                size: 11,
              },
            },
          },
          datalabels: {
            labels: {
              title: {
                font: {
                  weight: "bold",
                  size: 16,
                },
              },
            },
          },
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
</style>