<template>
  <div class="">
    <loading-overlay :overlay="loading"></loading-overlay>
    <div v-if="buttonScan" class="center-content">
      <h2 style="font-weight: bold">Escánear Pase de Acceso</h2>
      <v-icon color="gray" size="150" :style="{ width: '240px', height: '240px' }"
        >qr_code_scanner</v-icon
      >
      <span style="text-align: center; font-size: 12px; margin-bottom: 30px; color: gray"
        >Haga clic en el botón para iniciar el escaneo del pase de acceso QR.
      </span>
      <v-btn @click="mostrarCamaraFunction" block class="black" dark>Escanear</v-btn>
    </div>

    <v-dialog v-model="dialog_vistaprevia" max-width="600">
      <v-card>
        <v-card-title class="headline">Vista Previa</v-card-title>
        <v-card-text>
          <!-- Aquí se muestra la imagen desde el URL -->
          <v-img max-height="150" max-width="250" :src="imagen_url"></v-img>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="dialog_vistaprevia = false">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div v-if="mostrarRegistros">
      <template>
        <v-tabs v-model="tab" fixed-tabs dense>
          <v-tabs-slider></v-tabs-slider>
          <v-tab href="#tab-1"> Personal </v-tab>
          <v-tab href="#tab-2"> Vehiculos </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item value="tab-1">
            <v-list subheader dense two-line>
              <v-list-item-group color="gray">
                <v-text-field
                  style="margin-top: 10px"
                  outlined
                  dense
                  @input="filterList()"
                  v-model="filterQuery"
                  label="Buscar..."
                ></v-text-field>

                <v-virtual-scroll
                  :items="pasajeros"
                  :item-height="56"
                  :height="400"
                  v-slot="{ item, index }"
                >
                  <v-list-item
                    active-class="gray--text"
                    :key="index"
                    @click="viewInfoItem(item)"
                  >
                    <v-list-item-content>
                      <v-list-item-title style="font-size: 12px">
                        {{ item.nombre_completo }}
                      </v-list-item-title>

                      <v-list-item-subtitle style="font-size: 12px">
                        {{ item.compania }}
                      </v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-chip
                        v-if="item.access_logs.length == 0"
                        small
                        color="orange"
                        dark
                        class="ma-2"
                        >{{ item.access_logs.length }} accesos</v-chip
                      >
                      <v-chip
                        v-if="item.access_logs.length > 0"
                        small
                        color="green"
                        dark
                        class="ma-2"
                        >{{ item.access_logs.length }} acceso</v-chip
                      >
                    </v-list-item-action>
                  </v-list-item>
                </v-virtual-scroll>
              </v-list-item-group>
              <v-btn block color="black" @click="escanAgain" dark class="">
                Escanear otro pase
              </v-btn>
            </v-list>
          </v-tab-item>
          <v-tab-item value="tab-2">
            <v-card flat>
              <v-list subheader dense two-line>
                <v-list-item-group color="gray">
                  <v-text-field
                    style="margin-top: 10px"
                    outlined
                    dense
                    label="Buscar placa..."
                    @input="filterListVehiculs()"
                    v-model="filterQueryVehiculs"
                  ></v-text-field>

                  <v-virtual-scroll
                    :items="vehiculos"
                    :item-height="56"
                    :height="400"
                    v-slot="{ item, index }"
                  >
                    <v-list-item
                      active-class="gray--text"
                      :key="index"
                      @click="viewInfoItem(item)"
                    >
                      <v-list-item-content>
                        <v-list-item-title style="font-size: 12px">
                          {{ item.marca }}
                        </v-list-item-title>

                        <v-list-item-subtitle style="font-size: 12px">
                          {{ item.placa }}
                        </v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-chip
                          v-if="item.access_logs.length == 0"
                          small
                          color="orange"
                          dark
                          class="ma-2"
                          >{{ item.access_logs.length }} accesos</v-chip
                        >
                        <v-chip
                          v-if="item.access_logs.length > 0"
                          small
                          color="green"
                          dark
                          class="ma-2"
                          >{{ item.access_logs.length }} acceso</v-chip
                        >
                      </v-list-item-action>
                    </v-list-item>
                  </v-virtual-scroll>
                </v-list-item-group>
                <v-btn block color="black" @click="escanAgain" dark class="">
                  Escanear otro pase
                </v-btn>
              </v-list>
            </v-card>
          </v-tab-item>
        </v-tabs-items>

        <v-bottom-sheet v-model="sheet">
          <v-sheet
            class="text-center"
            height="400px"
            style="max-height: 480px; overflow-y: auto"
          >
            <div class="py-3 px-3">
              <h3>Gestión de Acceso</h3>
              <v-btn
                icon
                @click="triggerFileInput"
                :style="{ width: '80px', height: '80px' }"
              >
                <v-icon
                  color="gray"
                  size="40"
                  :style="{ width: '100px', height: '140px' }"
                  >photo_camera</v-icon
                >
              </v-btn>
              <input
                ref="fileInput"
                type="file"
                accept="image/*"
                capture="camera"
                style="display: none"
                @change="handleFileChange"
              />

              <v-img
                v-if="imagePreview"
                :src="imagePreview"
                max-width="50%"
                max-height="120"
                class="mt-4"
              />
              <div class="mt-6" style="font-size: 13px">
                <div v-if="this.tab == 'tab-1'">
                  <span style="font-size: 12px">
                    Adjunte la evidencia necesaria para otorgar acceso a
                    <strong>{{ itemSelected.nombre_completo }}</strong> de la compañia
                    <strong>{{ itemSelected.compania }}</strong> y luego presione el botón
                    para confirmar.
                  </span>
                </div>
                <div v-if="this.tab == 'tab-2'">
                  <span>
                    Para otorgar acceso al vehículo de marca
                    <strong>{{ itemSelected.marca }}</strong> con placa
                    <strong>{{ itemSelected.placa }}</strong> y color
                    <strong>{{ itemSelected.color }}</strong
                    >, adjunte la evidencia necesaria y asegúrese de que el seguro de
                    <strong>{{ itemSelected.compania_aseguradora }}</strong> con vigencia
                    <strong>{{ itemSelected.vigenciaSeguro }}</strong> esté vigente.
                    Luego, presione el botón para dar acceso.
                  </span>
                </div>
              </div>

              <!-- Botón de autorizar acceso -->
              <v-btn
                v-if="tab == 'tab-1'"
                :loading="loading"
                :disabled="!imagePreview"
                @click="autorizarAcceso"
                block
                class="mt-3"
                >Aprobar acceso a personal</v-btn
              >
              <v-btn
                v-if="tab == 'tab-2'"
                :loading="loading"
                :disabled="!imagePreview"
                @click="autorizarAccesoVehiculo"
                block
                class="mt-3"
                >Aprobar acceso a vehiculo</v-btn
              >

              <div class="py-3 px-3">
                <v-list v-model="listSelected" dense>
                  <v-subheader>Accesos registrados</v-subheader>
                  <v-list-item-group color="primary">
                    <v-list-item
                      v-for="(item, index) in itemSelected.access_logs"
                      :key="index"
                      @click="mostrarImagen(item.evidencia)"
                    >
                      <v-list-item-icon class="item-center">
                        <v-chip x-small> {{ index + 1 }}</v-chip>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ item.timestamp_acceso | moment("dddd Do MM YYYY, H:mm") }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                  </v-list-item-group>
                </v-list>
              </div>
            </div>
          </v-sheet>
        </v-bottom-sheet>

        <v-bottom-sheet v-model="sheetApprove">
          <v-sheet
            class="text-center"
            height="400px"
            style="max-height: 480px; overflow-y: auto"
          >
            <div class="py-3 px-3 center-content">
              <h3>Información de Acceso</h3>
              <v-list dense>
                <v-subheader>REPORTS</v-subheader>
                <v-list-item-group color="primary">
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon>check</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title> Acceso</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>

              <div class="mt-6" style="font-size: 13px">
                <div v-if="this.tab == 'tab-1'">
                  <span style="font-size: 12px">
                    El acceso de
                    <strong>{{ itemSelected.nombre_completo }}</strong> de la compañía
                    <strong>{{ itemSelected.compania }}</strong> fue autorizado el día
                    <strong>{{ formatDate(itemSelected.timestamp_acceso) }}</strong> a las
                    <strong>{{ formatTime(itemSelected.timestamp_acceso) }}</strong
                    >.
                  </span>
                </div>

                <div v-if="this.tab == 'tab-2'">
                  <span style="font-size: 12px">
                    El acceso del vehículo de marca
                    <strong>{{ itemSelected.marca }}</strong> con placa
                    <strong>{{ itemSelected.placa }}</strong> y color
                    <strong>{{ itemSelected.color }}</strong> fue registrado el día
                    <strong>{{ formatDate(itemSelected.timestamp_acceso) }}</strong> a las
                    <strong>{{ formatTime(itemSelected.timestamp_acceso) }}</strong
                    >. La aseguradora es
                    <strong>{{ itemSelected.compania_aseguradora }}</strong> con vigencia
                    hasta <strong>{{ itemSelected.vigenciaSeguro }}</strong
                    >.
                  </span>
                </div>
              </div>
            </div>
          </v-sheet>
        </v-bottom-sheet>
      </template>
    </div>

    <qrcode-stream
      v-if="mostrarCamara"
      :camera="camera"
      @decode="onDecode"
      @init="onInit"
    >
      <div v-show="showScanConfirmation" class="scan-confirmation">
        <v-icon x-large color="blue">mdi-barcode-scan</v-icon>
      </div>
    </qrcode-stream>
  </div>
</template>

<script>
import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from "vue-qrcode-reader";
import LoadingOverlay from "../components/Loading.vue";
import {
  getPassengerVehiculs,
  updatePassengerAccess,
  updateVehicleAccess,
} from "../../api/endpoints";
import { uploadImage } from "../../helpers/handleUploadsDocument";
import axios from "axios";
export default {
  name: "home",
  components: {
    QrcodeStream,
    LoadingOverlay,
  },
  data() {
    return {
      buttonScan: true,
      sheet: false,
      sheetApprove: false,
      imagePreview: null,
      filterQuery: null,
      filterQueryVehiculs: null,
      mostrarRegistros: false,
      info: [],
      fileImage: "",
      itemSelected: [],
      listSelected: "",
      tabs: [],
      camera: "auto",
      pasajeros: [],
      vehiculos: [],
      imagen_url: "",
      dialog_vistaprevia: "",
      tab: null,
      text:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      showScanConfirmation: false,
      mostrarCamara: false,
      loading: false,
    };
  },
  methods: {
    async onInit(promise) {
      try {
        this.loading = true;
        await promise;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
        this.showScanConfirmation = this.camera === "off";
      }
    },

    triggerFileInput() {
      this.$refs.fileInput.click();
    },

    handleFileChange(event) {
      const file = event.target.files[0];
      this.fileImage = file;
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imagePreview = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },

    async onDecode(content) {
      this.result = content;
      this.mostrarRegistros = true;
      this.pause();
      console.log(content);
      this.getDataQr(content);
      //await this.timeout(500);
      this.buttonScan = false;
      this.mostrarCamara = false;
    },

    pause() {
      this.camera = "off";
    },
    timeout(ms) {
      return new Promise((resolve) => {
        window.setTimeout(resolve, ms);
      });
    },

    mostrarCamaraFunction() {
      this.mostrarCamara = true;
      this.camera = "auto";
      this.buttonScan = false;
    },

    async getDataQr(_id) {
      this.loading = true;
      await getPassengerVehiculs(_id)
        .then((e) => {
          this.info = e;
          this.pasajeros = e.pasajeros;
          this.vehiculos = e.vehiculos;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    viewInfoItem(item) {
      if (item.estado == "Ingresado" ? (this.sheetApprove = true) : (this.sheet = true))
        this.imagePreview = null;
      this.itemSelected = item;
    },

    escanAgain() {
      this.pasajeros = [];
      this.vehiculos = [];
      this.mostrarRegistros = false;
      this.buttonScan = true;
    },

    async autorizarAcceso() {
      const data = {
        _id: this.info._id,
        evidencia: "",
        _idPassenger: this.itemSelected._id,
      };

      this.loading = true;

      try {
        // Sube la imagen y asigna la URL
        const imagen_url = await uploadImage(this.fileImage);
        data.evidencia = imagen_url;

        // Obtén el token de localStorage
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error("Token no disponible");
        }

        // Realiza la petición directamente
        const response = await axios.put("vehiculoPersonal/autorizar_acceso", data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        console.log(response.data);
        this.sheet = false;
        this.getDataQr(this.info._id);
      } catch (err) {
        console.error(err);
        this.$notify({
          title: "¡Alerta!",
          text: "El folio de la solicitud está vencido",
          type: "warning",
        });
      } finally {
        this.loading = false;
      }
    },

    formatDate(date) {
      const options = { year: "numeric", month: "long", day: "numeric" };
      return new Date(date).toLocaleDateString(undefined, options);
    },

    formatTime(date) {
      const options = { hour: "2-digit", minute: "2-digit" };
      return new Date(date).toLocaleTimeString(undefined, options);
    },

    async autorizarAccesoVehiculo() {
      let me = this;
      this.loading = true;
      const data = {
        _id: this.info._id,
        evidencia: "",
        _idVehiculo: this.itemSelected._id,
      };

      const imagen_url = await uploadImage(this.fileImage);
      data.evidencia = imagen_url;

      await updateVehicleAccess(data)
        .then((e) => {
          me.sheet = false;
          me.getDataQr(this.info._id);
        })
        .catch((err) => {
          console.log("first");
          me.$notify({
            title: "!Alerta!",
            text: "El folio de la solicitud esta vencido",
            type: "warning",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },

    filterList() {
      if (this.info.pasajeros) {
        this.pasajeros = this.info.pasajeros.filter((item) =>
          item.nombre_completo.toLowerCase().includes(this.filterQuery.toLowerCase())
        );
      }
    },
    filterListVehiculs() {
      if (this.info.vehiculos) {
        this.vehiculos = this.info.vehiculos.filter((item) =>
          item.placa.toLowerCase().includes(this.filterQueryVehiculs.toLowerCase())
        );
      }
    },

    mostrarImagen(imagen) {
      this.dialog_vistaprevia = true;

      this.imagen_url = imagen;
    },
  },
};
</script>

<style scoped>
.centered {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.center-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
