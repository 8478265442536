<template>
  <v-layout align-start>
    <v-flex>
      <v-data-table
        :headers="headers"
        :items="estadias"
        :search="search"
        class="elevation-1"
      >
        <template v-slot:item.estado="{ item }">
          <v-card-text v-if="item.estado" class="blue--text"
            >Activo</v-card-text
          >
          <v-card-text v-if="!item.estado" class="red--text"
            >Inactivo</v-card-text
          >
        </template>
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-btn @click="crearPDF()">
              <v-icon>print</v-icon>
            </v-btn>
            <v-toolbar-title>Estadias</v-toolbar-title>

            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-text-field
              class="text-xs-center"
              v-model="search"
              append-icon="search"
              label="Busqueda"
              single-line
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="1200px">
              <template v-slot:activator="{ on }">
                <v-btn
                  color="primary"
                  dark
                  class="mb-2"
                  @click="limpiar"
                  v-on="on"
                  >Nuevo Registro</v-btn
                >
              </template>
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-expansion-panels v-model="panel" multiple>
                    <v-expansion-panel >
                      <v-expansion-panel-header
                        ><strong>Datos de Embarcacion</strong> </v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                           <v-row>
                              <v-col cols="2" sm="2" md="2">
                                <v-autocomplete
                                  v-model="embarcacion"
                                  class="pa-0"
                                  hide-details
                                  label="Embarcacion"
                                  :items="embarcaciones"
                                  v-on:change="datosEmbarcacion()"
                                ></v-autocomplete>
                              </v-col>
                                <v-col cols="2" sm="2" md="2">
                                  <v-autocomplete 
                                   class="pa-0"
                                  hide-details
                                  v-model="agencia" 
                                  :items="agencias"  
                                  label="Agencia">
                                </v-autocomplete>

                              </v-col>
                              <v-col cols="2" sm="2" md="2">
                                <h3>
                                  Eslora: <strong>{{ eslora }}</strong>
                                </h3>
                              </v-col>
                              <v-col cols="2" sm="2" md="2">
                                <h3>
                                  TRB: <strong>{{ trb }}</strong>
                                </h3>
                              </v-col>
                              <v-col cols="2" sm="2" md="2">
                              
                                     <v-menu
                                        ref="menu"
                                        v-model="menu"
                                        :close-on-content-click="false"
                                        :return-value.sync="date"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="auto"
                                      >
                                        <template v-slot:activator="{ on, attrs }">
                                          <v-text-field
                                          class="pa-0"
                                            hide-details
                                            label="Periodo"
                                            v-model="periodo"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                          ></v-text-field>
                                        </template>
                                        <v-date-picker
                                          v-model="periodo"
                                          type="month"
                                          no-title
                                          scrollable
                                        >
                                          <v-spacer></v-spacer>
                                          <v-btn
                                            text
                                            color="primary"
                                            @click="menu = false"
                                          >
                                            Cancel
                                          </v-btn>
                                          <v-btn
                                            text
                                            color="primary"
                                            @click="$refs.menu.save(date)"
                                          >
                                            OK
                                          </v-btn>
                                        </v-date-picker>
                                    </v-menu>
                                
                              </v-col>
                            </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                        <v-expansion-panel >
                      <v-expansion-panel-header
                        ><strong>Datos de Estadia</strong> </v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                          <v-row>
                               <v-col cols="2" sm="2" md="2">
                                  <v-datetime-picker
                                    label="Fecha/Hora de Atraque"
                                    class="pa-0"
                                    hide-details
                                    v-model="atraque"
                                  >
                                  </v-datetime-picker>
                                </v-col>
                                <v-col cols="2" sm="2" md="2">
                                  <v-datetime-picker
                                    label="Fecha/Hora de Desatraque"
                                    class="pa-0"
                                    hide-details
                                    v-model="desatraque"
                                  >
                                  </v-datetime-picker>
                                </v-col>
                                <v-col cols="1" sm="1" md="1">
                                  <v-btn
                                    class="mx-2"
                                    fab
                                    dark
                                    small
                                    v-on:change="sumaPax()"
                                    @click="datosFechas"
                                    color="indigo"
                                  >
                                    <v-icon dark> directions_boat </v-icon>
                                  </v-btn>
                                </v-col>
                                <v-col cols="2" sm="2" md="2">
                                  <h3>
                                    Estadia: <strong>{{ diferenciaDeFechas }}</strong>
                                  </h3>
                                </v-col>

                                  <v-col cols="3" sm="3" md="3">
                                      <v-checkbox
                                        v-model="amarre_desamarre"
                                        :label="`Amarre/Desamarre: ${amarre_desamarre.toString()}`"
                                      ></v-checkbox>
                                    </v-col>

                              </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                          <v-expansion-panel >
                      <v-expansion-panel-header
                        ><strong>Pasajeros y Materiales</strong> </v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                          <v-row>
                              <v-row>
                                <v-col cols="2" sm="2" md="2">
                                    <v-text-field
                                      v-model="embarquePax"
                                      type="number"
                                      label="Embarque PAX"
                                      v-on:change="sumaPax()"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="2" sm="2" md="2">
                                    <v-text-field
                                      v-model="desembarquePax"
                                      type="number"
                                      label="Desembarque PAX"
                                      v-on:change="sumaPax()"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="2" sm="2" md="2">
                                <h3>
                                  Total PAX: <strong>{{ totalPax }}</strong>
                                </h3>
                                </v-col>
                                <v-col cols="2" sm="2" md="2">
                                    <v-text-field
                                      v-model="embarqueTon"
                                      type="number"
                                      label="Embarque (TON)"
                                      v-on:change="sumaTon()"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="2" sm="2" md="2">
                                    <v-text-field
                                      v-model="desembarqueTon"
                                      type="number"
                                      label="Desembarque (TON)"
                                      v-on:change="sumaTon()"
                                    ></v-text-field>
                                </v-col>
                              
                              </v-row>
                          </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                                 <v-expansion-panel >
                                 
                      <v-expansion-panel-header
                        ><strong>Calculos Portuarios</strong> </v-expansion-panel-header
                      >
                      
                      <v-expansion-panel-content>
                          <v-btn
                            tile
                            small
                            color="success"
                            @click="calculoPortuario"
                          >
                            <v-icon left>
                              functions
                            </v-icon>
                            Validar Calculo
                          </v-btn>

                          <v-row>
                              <v-row>
                                
                              <v-col cols="3" sm="3" md="3">
                                 <h4>Tarifa Pto Fijo : <strong style="color:blue">${{tptoFijo}}</strong></h4>
                              </v-col>

                              <v-col cols="3" sm="3" md="3">
                                <h4>Tarifa Pto Variable : <strong style="color:blue">${{tptoVariable}}</strong></h4>
                              </v-col>
                                <v-col cols="3" sm="3" md="3">
                                <h4>Cuota de Arribo : <strong style="color:blue">${{arribo}}</strong></h4>
                                </v-col>
                                 <v-col cols="3" sm="3" md="3">
                                <h4>Tarifa de Atraque : <strong style="color:blue">${{tarifa_atraque}}</strong></h4>
                                </v-col>
                                  <v-col cols="3" sm="3" md="3">
                                <h4>Tarifa Pasajeros : <strong style="color:blue">${{tarifa_pasajeros}}</strong></h4>
                                </v-col>
                                 <v-col cols="3" sm="3" md="3">
                                <h4>Amarre/Desamare : <strong style="color:blue">${{tarifa_amarre_desamarre}}</strong></h4>
                                </v-col>
                                  <v-col cols="3" sm="3" md="3">
                                <h4>Muellaje : <strong style="color:blue">${{muellaje}}</strong></h4>
                                </v-col>
                                  <v-col cols="3" sm="3" md="3">
                                      
                                </v-col>
                                 <v-col cols="12" sm="12" md="12">
                                      <v-divider></v-divider>
                                </v-col>

                              
                                  <v-col cols="3" sm="3" md="3">
                                <h4>Subtotal : <strong style="color:green">${{subtotal}}</strong></h4>
                                </v-col>
                                <v-col cols="3" sm="3" md="3">
                                <h4>APITAB 30% : <strong style="color:green">${{montoApitab}}</strong></h4>
                                </v-col>
                                 <v-col cols="3" sm="3" md="3">
                                <h4>Intermediación : <strong style="color:green">${{intermediacion}}</strong></h4>
                                </v-col>
                                 <v-col cols="3" sm="3" md="3">
                                <h4>Total : <strong style="color:green">${{total}}</strong></h4>
                                </v-col>
                              
                              </v-row>
                          </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                  </v-expansion-panels>

                  <v-container>                   
                      

                      <v-col cols="12" sm="6" md="12" v-show="valida">
                        <div
                          class="red--tex"
                          v-for="v in validaMensaje"
                          :key="v"
                          v-text="v"
                        ></div>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close"
                    >Cancelar</v-btn
                  >
                  <v-btn color="blue darken-1" text @click="guardar"
                    >Guardar</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>

                <v-dialog v-model="dialogCalculos" max-width="1200px">
            
              <v-card>

                  <v-card>
                <v-card-title>
                  <span class="headline">Calculos Portuarios</span>
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close"
                    >Cancelar</v-btn
                  >
                </v-card-actions>
              </v-card>
               
              </v-card>
            </v-dialog>
            
            <v-dialog v-model="adModal" max-width="290">
              <v-card>
                <v-card-title class="headline" v-if="adAccion == 1"
                  >Activar Item</v-card-title
                >
                <v-card-title class="headline" v-if="adAccion == 2"
                  >Desactivar Item</v-card-title
                >

                <v-card-text>
                  Estas a punto de
                  <span v-if="adAccion == 1">Activar</span>
                  <span v-if="adAccion == 2">Desactivar</span>
                  la embarcacion {{ adNombre }}
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn @click="activarDesactivarCerrar()" color="green" small
                    >Cancelar</v-btn
                  >
                  <v-btn
                    v-if="adAccion == 1"
                    @click="activar()"
                    color="blue"
                    small
                    >Activar</v-btn
                  >
                  <v-btn
                    v-if="adAccion == 2"
                    @click="desactivar()"
                    color="red"
                    small
                    >Desactivar</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.action="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">edit</v-icon>
        
          <v-icon small class="mr-2" @click="DesgloceDeCalculo(item)">functions</v-icon>
        
        </template>
      
        <template v-slot:no-data>
          <v-btn color="primary" @click="listar()">Resetear</v-btn>
        </template>
      </v-data-table>
    </v-flex>
  </v-layout>
</template>
<script>
import axios from "axios";
import download from "download-file";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import S3FileUpload from "@/components/S3FileComponent";
import "font-awesome/css/font-awesome.min.css"; // Ensure you are using css-loader
import S3 from "aws-s3";
import swal from "sweetalert";
import moment from "moment";

export default {
  components: {
    S3FileUpload,
  },
  icons: {
    iconfont: "fa4",
  },
  data() {
    return {
      dialog: false,
      dialogCalculos:false,
      search: "",
      embarcaciones: [],
      headers: [
        { text: "Actions", value: "action", sortable: false },
        { text: "Embarcacion", value: "embarcacion.nombre", sortable: true },
        { text: "Agencia", value: "agencia.nombre", sortable: true },
        { text: "Periodo", value: "periodo", sortable: true },
        { text: "Atraque", value: "horaAtraque", sortable: false },
        { text: "Desatraque", value: "horaDesatraque", sortable: false },
        { text: "Estatus", value: "estatus", sortable: false },
       
      ],
      editedIndex: -1,
      _id: "",
      nombreEmbarcacion: "",
      nombre: "",
      armador: "",
      tipo: "",
      tbr: "",
      embarqueTon:'',
      date: null,
      desembarqueTon:'',
      embarquePax: "",
      desembarquePax: "",
      tarifa_pasajeros: "",
      estadias:[],
      atraque: "",
      desatraque: "",
      tarifa_amarre_desamarre: "",
      diferenciaDeFechas: "",
      datosParaFormula: [],
      arribo: "",
      totalPax: "",
      subtotal: "",
      eslora: "",
      intermediacion: "",
      montoApitab: "",
      total: "",
      manga: "",
      agencias:[],
      agencia:'',
      amarre_desamarre:'',
      muellaje: "",
      trn: "",
      puntal: "",
      imo: "",
      tptoFijo: "",
      tptoVariable: "",
      embarcacion:'',
      tarifa_atraque: "",
      calado: "",
      valida: 0,
      validaMensaje: [],
      agencia:'',
      periodo:'',
      adModal: 0,
      adAccion: 0,
      adNombre: "",
      adId: "",
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo Registro" : "Editar Registro";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.listar();
    this.selectEmbarcaciones();
    this.selectAgencias();
  },

  methods: {
    datosEmbarcacion() {
      this.nombreEmbarcacion = this.embarcacion;
      let header = { Token: this.$store.state.token };
      let configuracion = { headers: header };
      let me = this;
      axios
        .get(
          "embarcacion/queryDatos?_id=" + this.nombreEmbarcacion,
          configuracion
        )
        .then(function (response) {
          me.datosParaFormula = response.data;
          me.eslora = me.datosParaFormula.eslora;
          me.trb = me.datosParaFormula.tbr;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    sumaPax() {
      let Number1 = parseInt(this.embarquePax);
      let Number2 = parseInt(this.desembarquePax);

      this.totalPax = Number1 + Number2;
      console.log(this.totalPax);
    },

    datosFechas() {
      var fecha1 = moment(this.atraque, "YYYY-MM-DD HH:mm:ss");
      var fecha2 = moment(this.desatraque, "YYYY-MM-DD HH:mm:ss");
      var minutos;
      var minutosFecha1 = new Date(fecha1);
      var minutosFecha2 = new Date(fecha2);

      //Prueba con milisegundos
      var sumaDeFechas = fecha2 - fecha1;
      var minutos = Math.floor((sumaDeFechas / 1000 / 60) << 0);
      console.log(minutos);
      var resultado = Math.round(minutos / 60);
      this.diferenciaDeFechas = resultado;

      //Hasta aqui termina la prueba
    },

    selectAgencias() {
          let me = this;
          let agencaiArray = [];
          let header = { Token: this.$store.state.token };
          let configuracion = { headers: header };
          axios
            .get("agencia/listActivos", configuracion)
            .then(response => {
              agencaiArray = response.data;
              agencaiArray.map(function(x) {
                me.agencias.push({ text: x.nombre, value: x._id });
              });
            })
            .catch(function(error) {
              console.log(error);
            });
        },

    calculoPortuario() {
      let ptoFijo = 6476.4;
      let ptoVariable = 3.89;
      let cuotaArribo = 630;
      let atraquesxhora = 2.63;
      let muellajeT = 6.3;
      let emb_des_pss = 20;
      let amarre10 = 630;
      let tarifaApitab = 1.85;
      let precioPasajero = 2.21;

      if (parseInt(this.trb) > 500) {
        this.tptoFijo = new Intl.NumberFormat().format(
          Number.parseFloat(ptoFijo).toFixed(2)
        );
      } else {
        this.tptoFijo = 0;
      }

      if (parseInt(this.trb) > 500) {
        this.tptoVariable = Number.parseFloat(this.trb * ptoVariable).toFixed(
          2
        );
      } else {
        this.tptoVariable = 0;
      }

      if (this.amarre_desamarre) {
        this.arribo = cuotaArribo;
      } else {
        this.arribo = 0;
      }

      this.tarifa_atraque = Number.parseFloat(
        atraquesxhora * this.diferenciaDeFechas * this.eslora
      ).toFixed(2);
      this.tarifa_pasajeros = Number.parseFloat(
        this.totalPax * emb_des_pss
      ).toFixed(2);

      if (this.amarre_desamarre) {
        this.tarifa_amarre_desamarre = amarre10;
      } else {
        this.tarifa_amarre_desamarre = 0;
      }

      if (this.embarquePax == 0 && this.desembarquePax == 0) {
        this.muellaje = 0;
      } else {
        this.muellaje = Number.parseFloat(
          (parseFloat(this.embarqueTon) + parseFloat(this.desembarqueTon)) *
            parseFloat(muellajeT)
        ).toFixed(2);
      }

      const formatoMexico = (number) => {
      const exp = /(\d)(?=(\d{3})+(?!\d))/g;
      const rep = '$1,';
      return number.toString().replace(exp,rep);
    }

    let subT =  Number.parseFloat(
        parseFloat(this.tptoFijo) +
          parseFloat(this.tptoVariable) +
          parseFloat(this.arribo) +
          parseFloat(this.tarifa_atraque) +
          parseFloat(this.tarifa_pasajeros) +
          parseFloat(this.tarifa_amarre_desamarre) +
          parseFloat(this.muellaje)
    ).toFixed(2);



    this.subtotal = formatoMexico (subT)

     let var1 = this.tptoFijo * 0.7;
      let var2 = 0;
      if (this.trb >= 500) {
        var2 = this.trb * ptoVariable;
      } else {
        var2 = 0;
      }
    
    let montoAtb = (
        Number.parseInt(var1 + var2) +
        this.arribo * 0.7 +
        tarifaApitab * this.eslora * this.diferenciaDeFechas +
        this.totalPax * precioPasajero +
        this.arribo * 0.7 +
        this.muellaje * 0.7
      ).toFixed(2)

    this.montoApitab = formatoMexico(montoAtb)


    let interM= (parseFloat(subT) * 0.2).toFixed(2)
    this.intermediacion = formatoMexico(interM)

    let t = ((parseFloat(subT) + parseFloat(interM)) * 1.16).toFixed(2)
    this.total = formatoMexico(t);
   // this.total = this.tptoFijo + this.tptoVariable + this.arribo + this.tarifa_atraque + this.tarifa_pasajeros + this.tarifa_amarre_desamarre + this.muellaje
     

     


 
      //this.montoApitab = Number.parseFloat(parseFloat(var1)+parseFloat(var2)+(parseFloat(this.arribo)*0.7)+(parseFloat(this.tarifa_atraque)*parseFloat(this.eslora)*parseFloat(this.estadia))+(parseFloat(this.total)*parseFloat(precioPasajero))+(parseFloat(this.amarre_desamarre)*0.7)+(parseFloat(this.muellaje)*0.7)).toFixed(2);
    },

    

    crearPDF() {
      var columns = [
        { title: "Nombre", dataKey: "nombre" },
        { title: "Armador", dataKey: "armador" },
        { title: "Tipo", dataKey: "tipo" },
        { title: "TBR", dataKey: "tbr" },
        { title: "Matricula", dataKey: "matricula" },
        this.eslora * { title: "Eslora", dataKey: "eslora" },
        { title: "Manga", dataKey: "manga" },
        { title: "Calado", dataKey: "calado" },
      ];
      var rows = [];
      this.embarcaciones.map((x) => {
        rows.push({
          nombre: x.nombre,
          armador: x.armador,
          tipo: x.tipo,
          tbr: x.tbr,
          trn: x.trn,
          eslora: x.eslora,
          manga: x.manga,
          calado: x.calado,
        });
      });
      var doc = new jsPDF("p", "pt");
      doc.autoTable(columns, rows, {
        margin: { top: 60 },
        addPageContent: function (data) {
          doc.text("Lista de Embarcaciones", 40, 30);
        },
      });
      doc.save("embarcaciones.pdf");
    },

    validar() {
      this.valida = 0;
      this.validaMensaje = [];
      if (this.nombre.length < 1) {
        this.validaMensaje.push("El nombre es obligatorio");
      }
      if (this.armador.length < 1) {
        this.validaMensaje.push("El armador es obligatorio");
      }
      if (this.tipo.length < 1) {
        this.validaMensaje.push("El tipo de embarcación es obligatorio");
      }
      if (this.tbr.length < 1) {
        this.validaMensaje.push("El TBR es obligatorio");
      }
      if (this.matricula.length < 1) {
        this.validaMensaje.push("El trn es obligatorio");
      }
      if (this.eslora.length < 1) {
        this.validaMensaje.push("La Eslora es obligatorio");
      }
      if (this.nombre.manga < 1) {
        this.validaMensaje.push("La manga es obligatorio");
      }
      if (this.calado.length < 1) {
        this.validaMensaje.push("El calado es obligatorio");
      }
      if (this.validaMensaje.length) {
        this.valida = 1;
      }
      return this.valida;
    },

    limpiar() {
     
        (this.validaMensaje = []),
        (this.editedIndex = -1);
    },
    guardar() {
      let me = this;
      let header = { Token: this.$store.state.token };
      let configuracion = { headers: header };

      if (this.editedIndex > -1) {
      
        axios
          .put(
            "embarcacion/update",
            {
              _id: this._id,
              nombre: this.nombre,
              armador: this.armador,
              tipo: this.tipo,
              tbr: this.tbr,
              matricula: this.matricula,
              eslora: this.eslora,
              bandera: this.bandera,
              pto_matricula: this.pto_matricula,
              trn: this.trn,
              puntal: this.puntal,
              imo: this.imo,
              manga: this.manga,
              calado: this.calado,
              certificado_a: this.nombreArchivo,
            },
            configuracion
          )
          .then(function (response) {
            me.S3Client.uploadFile(file, me.newFileName).finally(() => {});
            swal(
              "Embarcación Actualizada",
              "Embarcación Actualizada exitosamente",
              "success"
            );
            me.limpiar();
            me.close();
            me.listar();
          })
          .catch(function (error) {
            me.close, swal("Error", "Verifique los datos ingresados", "error");
          });
      } else {
        //Nuevo
       
        axios
          .post(
            "estadia/add",
            {
              embarcacion: this.embarcacion,
              agencia: this.agencia,
              periodo: this.periodo,
              amarre: this.amarre_desamarre,
              embarquePax: this.embarquePax,
              desembarquePax: this.desembarquePax,
              embarqueTon: this.embarqueTon,
              desembarqueTon: this.desembarqueTon,
              horaAtraque:this.atraque,
              horaDesatraque:this.desatraque
            },
            configuracion
          )
          .then(function (response) {
            swal(
              "Registro agregado con Exito",
              "Nuevo registro de estadia agregado",
              "success"
            );
            me.limpiar();
            me.close();
            me.listar();
          })
          .catch(function (error) {
            me.close();
            swal("Error", "Verifique los datos ingresados" + error, "error");
          });
      }
    },
    listar() {
      let header = { Token: this.$store.state.token };
      let configuracion = { headers: header };
      let me = this;
      axios
        .get(
          "estadia/list",
          configuracion
        )
        .then(function(response) {
          me.estadias = response.data;
        })
        .catch(function(error) {
          console.log(error);
        });
    },

   
    DesgloceDeCalculo(item){
       this.dialogCalculos = true;
       console.log('item')
    },

    selectEmbarcaciones() {
      let me = this;
      let embarcacionArray = [];
      let header = { Token: this.$store.state.token };
      let configuracion = { headers: header };
      axios
        .get("embarcacion/listActivos", configuracion)
        .then((response) => {
          embarcacionArray = response.data;
          embarcacionArray.map(function (x) {
            me.embarcaciones.push({ text: x.nombre, value: x._id });
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    editItem(item) {
      this._id = item._id;
      this.nombre = item.nombre;
      this.armador = item.armador;
      this.eslora = item.eslora;
      this.tbr = item.tbr;
      this.file = item.certificado_a;
      this.trn = item.trn;
      this.tipo = item.tipo;
      this.bandera = item.bandera;
      this.pto_matricula = item.pto_matricula;
      this.trn = item.trn;
      this.puntal = item.puntal;
      this.imo = item.imo;
      this.matricula = item.matricula;
      this.manga = item.manga;
      this.calado = item.calado;
      this.editedIndex = 1;

      this.dialog = true;
    },

    download(item) {
      axios
        .get("embarcacion/getCertificado/" + item.certificado_a, {
          responseType: "arraybuffer",
        })
        .then(function (response) {
          let blob = new Blob([response.data], { type: "content-type" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "certificado_a.pdf";
          link.click();
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    downloadCertificado(item) {
      window.open(item.certificado_a, "_blank");
    },

    activarDesactivarMostrar(accion, item) {
      this.adModal = 1;
      this.adNombre = item.nombre;
      this.adId = item._id;
      if (accion == 1) {
        this.adAccion = 1;
      } else if (accion == 2) {
        this.adAccion = 2;
      } else {
        this.adMmodal = 0;
      }
    },
    activar() {
      let me = this;

      axios
        .put("embarcacion/activate", { _id: this.adId })
        .then(function (response) {
          (me.adModal = 0),
            (me.adAccion = 0),
            (me.adNombre = ""),
            (me.adId = ""),
            me.listar();
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    desactivar() {
      let me = this;
      let header = { Token: this.$store.state.token };
      let configuracion = { headers: header };
      axios
        .put("embarcacion/deactivate", { _id: this.adId }, configuracion)
        .then(function (response) {
          (me.adModal = 0),
            (me.adAccion = 0),
            (me.adNombre = ""),
            (me.adId = ""),
            me.listar();
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    activarDesactivarCerrar() {
      this.adModal = 0;
    },

    close() {
      this.dialog = false;
    },
  },
};
</script>