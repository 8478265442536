import axios from "axios";
import store from "../store/index";

// const api = axios.create({
//   baseURL: "https://sam.apitab.mx/api/",
// });

// // Interceptor para agregar el token a todas las solicitudes
// api.interceptors.request.use(
//   (config) => {
//     const token = localStorage.getItem("token");
//     if (token) {
//       config.headers.Authorization = `Bearer ${token}`;
//     }
//     return config;
//   },
//   (error) => Promise.reject(error)
// );

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response &&
      error.response.status === 500 &&
      error.response.data.error === "Token no valido"
    ) {
      store.dispatch("salir"); // Asumiendo que tienes una mutación 'logout' para borrar el token
      //router.push('/login'); // Redirige al login
    }
    return Promise.reject(error);
  }
);

// Vehículos personales
export const getPassengerVehiculs = async (_id) => {
  const token = localStorage.getItem("token");
  const { data } = await axios.get(
    `vehiculoPersonal/list_passenger_vehicles/${_id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return data;
};

export const updatePassengerAccess = async (datos) => {
  const token = localStorage.getItem("token");
  const { data } = await axios.put("vehiculoPersonal/autorizar_acceso", datos);
  return data;
};

export const updateVehicleAccess = async (datos) => {
  const token = localStorage.getItem("token");
  const { data } = await axios.put(
    "vehiculoPersonal/autorizar_acceso_vehiculo",
    datos,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return data;
};

// Tarifas
export const guardarCambiosTarifas = async (datos) => {
  const token = localStorage.getItem("token");
  const { data } = await axios.post(
    "solicitudUnica/guardar_cambios_tarifas/",
    datos,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return data;
};

export const obtenerTarifa = async () => {
  const token = localStorage.getItem("token");
  const { data } = await axios.get("solicitudUnica/obtener_tarifas/", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};

export const reprogramarSolicitud = async (datos) => {
  const token = localStorage.getItem("token");
  const { data } = await axios.put(
    "solicitudUnica/reprogramar_solicitud/",
    datos,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return data;
};

export const getFoliosSolicitudes = async () => {
  const token = localStorage.getItem("token");
  const { data } = await axios.get("solicitudUnica/obtener_folios", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};

// Bitácora Embarcación
export const insertBitacoraEmbarcación = async (datos) => {
  const token = localStorage.getItem("token");
  const { data } = await axios.post("bitacoraEmbarcacion/add", datos, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};

export const listAccesoFolio = async (folio) => {
  const token = localStorage.getItem("token");
  const { data } = await axios.get(`bitacoraEmbarcacion/list/${folio}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};

export const listBitacora = async () => {
  const token = localStorage.getItem("token");
  const { data } = await axios.get("bitacoraEmbarcacion/list_bitacora", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};

// Usuario
export const forgotPassword = async (email) => {
  const token = localStorage.getItem("token");
  const { data } = await axios.post(
    "usuario/request-reset",
    { email },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return data;
};

export const resetPassword = async (tokenParam, password) => {
  const token = localStorage.getItem("token");
  const { data } = await axios.post(
    "usuario/reset-password",
    { token: tokenParam, password },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return data;
};

// Tickets
export const crearTicket = async (datos) => {
  const token = localStorage.getItem("token");
  const { data } = await axios.post("tickets/add", datos, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};

export const getTickets = async () => {
  const token = localStorage.getItem("token");
  const { data } = await axios.get("tickets/", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};

export const allTickets = async () => {
  const token = localStorage.getItem("token");
  const { data } = await axios.get("tickets/all_tickets", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};

export const getTicketsEstatus = async (estatus) => {
  const token = localStorage.getItem("token");
  const { data } = await axios.post(
    "tickets/tickets",
    { estatus },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return data;
};

export const addLogs = async (datos, _id) => {
  const token = localStorage.getItem("token");
  const { data } = await axios.post(`tickets/add_logs/${_id}`, datos, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};

export const updateEstatus = async (estatus, _id) => {
  const token = localStorage.getItem("token");
  const { data } = await axios.post(
    `tickets/update_estatus/${_id}`,
    { estatus },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return data;
};

export const getTicketsId = async (_id) => {
  const token = localStorage.getItem("token");
  const { data } = await axios.get(`tickets/get_ticket_id/${_id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};
