import Vue from "vue";
import Router from "vue-router";
import Home from "../views/Home.vue";
import Embarcacion from "../components/Embarcacion.vue";
import Archivo from "../components/Archivo.vue";
import Login from "../components/Login.vue";
import store from "../store";
import Usuario from "../components/Usuario.vue";
import Pasajeros from "../components/Pasajeros.vue";
import SolicitudUnica from "../components/SolicitudUnica.vue";
import CambiosGuardia from "../components/CambiosGuardia.vue";
import EstatusSolicitud from "../components/EstatusSolicitud";
import Agencia from "../components/Agencia.vue";
import Solicitudes from "../components/Solicitudes.vue";
import Consultas from "../components/Consultas.vue";
import SolicitudUnicaAdmin from "../components/SolicitudUnicaAdmin.vue";
import SolicitudApitab from "../components/SolicitudApitab.vue";
import CambioGuardiaApi from "../components/CambioGuardiaApi.vue";
import Test from "../components/Test.vue";
import Reporte1 from "../components/Reporte1";
import VehiculosPersonal from "../components/VehiculosPersonal.vue";
import Bitacora from "../components/Bitacora.vue";
import VehiculoPersonalApi from "../components/VehiculosPersonalApi";
import VehiculoPersonalAdmin from "../components/VehiculosPersonalAdmin.vue";
import Reportes from "../components/Reportes.vue";
import Companias from "../components/Companias.vue";
import BitacoraEmbarcaciones from "../components/BitacoraEmbarcaciones.vue";
import Perfil from "../components/Perfil.vue";
import PspCosteo from "../components/PspCosteo.vue";
import pspCosteoRegistro from "../components/PspCosteoRegistro.vue";
import altaPsp from "../components/AltaPsp.vue";
import MovimientosAMLS from "../components/MovimientosAMLS.vue";
import Estadias from "../components/Estadias.vue";
import Clientes from "../components/Clientes.vue";
import AgenciamientoPemex from "../components/AgenciamientoPemex.vue";
import ReportesPemex from "../components/ReportesPemex";
import SolicitudUnicaAgencia from "../views/agencias/SolicitudUnica.vue";
import CambiosGuardaAgencia from "../views/agencias/CambiosGuardia.vue";
import MisPasajerosAgencia from "../views/agencias/MisPasajeros.vue";
import vehiculosPersonalAgencia from "../views/agencias/VehiculosPersonal";
import reportesAdmin from "../views/admin/reportes";
//APROBACIONES
import solicitudUnicaAprob from "../views/admin/aprobaciones/solicitudUnicaAprob.vue";
import solicitudUnicaAprob2 from "../views/admin/aprobaciones/solicitudUnicaAprob2.vue";

import solicitudPersonalAprob from "../views/admin/aprobaciones/solicitudPersonalAprob.vue";
import solicitudPersonalAprob2 from "../views/admin/aprobaciones/solicitudPersonalAprob2.vue";

import solicitudVehiculosAprob from "../views/admin/aprobaciones/solicitudVehiculosAprob.vue";
import solicitudVehiculosAprob2 from "../views/admin/aprobaciones/solicitudVehiculosAprob2.vue";

//VIGILANTE
import GestionAcceso from "../views/admin/GestionAcceso.vue";
//Configuración
import Configuracion from "../views/config/Configuracion.vue";
import ForgotPassword from "../views/config/ForgotPassword.vue";
import SoporteTecnico from "../views/config/SoporteTecnico.vue";
import ResetPassword from "../views/config/ResetPassword.vue";
import SeguimientoEstatus from "../views/config/SeguimientoEstatus.vue";
import AtencionTickets from "../views/config/AtencionTickets.vue";
//Mesa de control
import BitacoraAgenciamiento from "../views/admin/BitacoraAgenciamiento.vue";

Vue.use(Router);

var router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/home",
      name: "home",
      component: Home,
      meta: {
        administrador: true,
        cliente: true,
        apitab: true,
        operaciones: true,
        psp: true,
        root: true,
        vigilante: true,
        agencia: true,
        microsip: true,
        opib: true,
        mesa_control: true,
        visor: true,
        soporte: true,
      },
    },
    {
      path: "/soporte",
      name: "soporte",
      component: SoporteTecnico,
      meta: {
        administrador: true,
        cliente: true,
        apitab: true,
        psp: true,
        root: true,
        operaciones: true,

        vigilante: true,
        agencia: true,
        microsip: true,
        opib: true,
        mesa_control: true,
        visor: true,
      },
    },
    {
      path: "/atencion_tickets",
      name: "atencion_tickets",
      component: AtencionTickets,
      meta: {
        soporte: true,
        root: true,
      },
    },
    {
      path: "/soporte/:estatus",
      name: "soporte_estatus",
      component: SeguimientoEstatus,
      meta: {
        administrador: true,
        cliente: true,
        apitab: true,
        psp: true,
        root: true,
        vigilante: true,
        operaciones: true,
        agencia: true,
        microsip: true,
        opib: true,
        mesa_control: true,
        visor: true,
      },
    },
    {
      path: "/forgot_password",
      name: "forgot_password",
      component: ForgotPassword,
      meta: {
        libre: true,
      },
    },
    {
      path: "/reset",
      name: "resetPassword",
      component: ResetPassword,
      meta: {
        libre: true,
      },
    },

    {
      path: "/solicitud_unica",
      name: "solicitud_unica",
      component: SolicitudUnica,
      meta: {
        cliente: true,
        root: true,
        administrador: true,
        opib: true,
        operaciones: true,
      },
    },
    {
      path: "/clientes",
      name: "clientes",
      component: Clientes,
      meta: {
        administrador: true,
      },
    },
    {
      path: "/solicitud_unica_admin",
      name: "solicitud_unica_admin",
      component: SolicitudUnicaAdmin,
      meta: {
        administrador: true,
        operaciones: true,
        root: true,
      },
    },
    {
      path: "/pspcosteo",
      name: "pspcosteo",
      component: PspCosteo,
      meta: {
        apitab: true,
      },
    },
    {
      path: "/movimientosamls",
      name: "movimientosamls",
      component: MovimientosAMLS,
      meta: {
        administrador: true,
      },
    },
    {
      path: "/pspcosteoregistro",
      name: "pspcosteoregistro",
      component: pspCosteoRegistro,
      meta: {
        administrador: true,
      },
    },
    {
      path: "/reportes",
      name: "reportes",
      component: Reportes,
      meta: {
        root: true,
        cliente: true,
      },
    },
    {
      path: "/vehiculos_personal_admin",
      name: "vehiculosPersonalAdmin",
      component: VehiculoPersonalAdmin,
      meta: {
        administrador: true,
        root: true,
        operaciones: true,
      },
    },
    {
      path: "/pasajeros",
      name: "pasajeros",
      component: Pasajeros,
      meta: {
        cliente: true,
      },
    },

    {
      path: "/companias",
      name: "companias",
      component: Companias,
      meta: {
        cliente: true,
      },
    },
    {
      path: "/solicitudes",
      name: "solicitudes",
      component: Solicitudes,
      meta: {
        administrador: true,
        operaciones: true,
        root: true,
      },
    },

    {
      path: "/consultas",
      name: "consultas",
      component: Consultas,
      meta: {
        administrador: true,
      },
    },

    {
      path: "/reporte1",
      name: "reporte1",
      component: Reporte1,
      meta: {
        root: true,
      },
    },

    {
      path: "/agencia",
      name: "agencia",
      component: Agencia,
      meta: {
        cliente: true,
        root: true,
        opib: true,
        administrador: true,
      },
    },
    {
      path: "/cambios_guardia",
      name: "cambios_guardia",
      component: CambiosGuardia,
      meta: {
        cliente: true,
        administrador: true,
        root: true,
        vigilante: true,
        operaciones: true,
      },
    },
    {
      path: "/agenciamiento_pemex",
      name: "agenciamiento_pemex",
      component: AgenciamientoPemex,
      meta: {
        cliente: true,
        administrador: true,
      },
    },

    {
      path: "/reportesPemex",
      name: "reportesPemex",
      component: ReportesPemex,
      meta: {
        cliente: true,
        administrador: true,
      },
    },

    {
      path: "/estatus_solicitud",
      name: "estatus_solicitud",
      component: EstatusSolicitud,
      meta: {
        cliente: true,
      },
    },
    {
      path: "/embarcacion",
      name: "embarcacion",
      component: Embarcacion,
      meta: {
        root: true,
        administrador: true,
        cliente: true,
        opib: true,
      },
    },
    {
      path: "/usuario",
      name: "usuario",
      component: Usuario,
      meta: {
        root: true,
        administrador: true,
      },
    },
    {
      path: "/altapsp",
      name: "altapsp",
      component: altaPsp,
      meta: {
        root: true,
      },
    },
    {
      path: "/test",
      name: "test",
      component: Test,
      meta: {
        administrador: true,
      },
    },

    {
      path: "/login",
      name: "login",
      component: Login,
      meta: {
        libre: true,
      },
    },

    {
      path: "/solicitud",
      name: "solicitud_apitab",
      component: SolicitudApitab,
      meta: {
        apitab: true,
      },
    },
    {
      path: "/vehiculosPersonalApi",
      name: "vehiculos_personal_api",
      component: VehiculoPersonalApi,
      meta: {
        apitab: true,
      },
    },
    {
      path: "/cambio_guardia",
      name: "cambio_guardia_api",
      component: CambioGuardiaApi,
      meta: {
        apitab: true,
      },
    },
    {
      path: "/vehiculos_personal",
      name: "vehiculos_personal",
      component: VehiculosPersonal,
      meta: {
        cliente: true,
        psp: true,
      },
    },
    {
      path: "/bitacora",
      name: "bitacora",
      component: Bitacora,
      meta: {
        administrador: true,
        cliente: true,
        vigilante: true,
      },
    },

    {
      path: "/bitacoraEmbarcaciones",
      name: "bitacoraEmbarcaciones",
      component: BitacoraEmbarcaciones,
      meta: {
        administrador: true,
      },
    },
    {
      path: "/estadias",
      name: "estadias",
      component: Estadias,
      meta: {
        administrador: true,
      },
    },
    // AGENCIAS

    {
      path: "/solicitudUnica",
      name: "solicitudUnicaAgencia",
      component: SolicitudUnicaAgencia,
      meta: {
        agencia: true,
        administrador: true,
        visor: true,
        opib: true,
        operaciones: true,
        root: true,
      },
    },
    {
      path: "/cambiosGuardia",
      name: "cambiosGuardiaAgencia",
      component: CambiosGuardaAgencia,
      meta: {
        agencia: true,
        administrador: true,
        operaciones: true,
        visor: true,

        opib: true,
        root: true,
      },
    },
    {
      path: "/misPasajeros",
      name: "misPasajerosAgencia",
      component: MisPasajerosAgencia,
      meta: {
        agencia: true,
        visor: true,

        root: true,
      },
    },
    {
      path: "/vehiculosPersonal",
      name: "vehiculosPersonalAgencia",
      component: vehiculosPersonalAgencia,
      meta: {
        agencia: true,
        administrador: true,
        opib: true,
        operaciones: true,
        visor: true,

        root: true,
      },
    },
    {
      path: "/reportes_admin",
      name: "reportes_admin",
      component: reportesAdmin,
      meta: {
        root: true,
        administrador: true,
        cliente: true,
      },
    },

    //APROBACIONES
    {
      path: "/solicitud_aunica_aprob/:_id",
      name: "solicitud_aunica_aprob",
      component: solicitudUnicaAprob,
      meta: {
        administrador: true,
        opib: true,
      },
    },
    {
      path: "/solicitud_personal_aprob/:_id",
      name: "solicitud_personal_aprob",
      component: solicitudPersonalAprob,
      meta: {
        administrador: true,
        opib: true,
      },
    },
    {
      path: "/solicitud_vehiculos_aprob/:_id",
      name: "solicitud_vehiculos_aprob",
      component: solicitudVehiculosAprob,
      meta: {
        administrador: true,
        opib: true,
      },
    },

    //APROBACIONES 2
    {
      path: "/solicitud_aunica_aprob2/:_id",
      name: "solicitud_aunica_aprob2",
      component: solicitudUnicaAprob2,
      meta: {
        operaciones: true,
      },
    },
    {
      path: "/solicitud_personal_aprob2/:_id",
      name: "solicitud_personal_aprob2",
      component: solicitudPersonalAprob2,
      meta: {
        operaciones: true,
      },
    },

    {
      path: "/solicitud_vehiculos_aprob2/:_id",
      name: "solicitud_vehiculos_aprob2",
      component: solicitudVehiculosAprob2,
      meta: {
        operaciones: true,
      },
    },
    {
      path: "/gestion_acceso",
      name: "gestion_acceso",
      component: GestionAcceso,
      meta: {
        vigilante: true,
        root: true,
      },
    },
    {
      path: "/configuracion",
      name: "configuracion",
      component: Configuracion,
      meta: {
        mesa_control: true,
        root: true,
      },
    },
    {
      path: "/bitacora_agenciamiento",
      name: "bitacora_agenciamiento",
      component: BitacoraAgenciamiento,
      meta: {
        mesa_control: true,
        administrador: true,
        root: true,
        operaciones: true,
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("token");
  const usuario = store.state.usuario;

  // Si la ruta es libre, continuar
  if (to.matched.some((record) => record.meta.libre)) {
    next();
  } else if (token) {
    // Si hay un token, verifica que el usuario está autenticado
    if (!usuario) {
      store.dispatch("autoLogin").then(() => {
        // Una vez autenticado, verifica los roles
        checkRoleAndProceed(to, next);
      });
    } else {
      // Usuario ya autenticado, verifica los roles
      checkRoleAndProceed(to, next);
    }
  } else {
    // Si no hay token, redirige al login
    next({ name: "login" });
  }
});

function checkRoleAndProceed(to, next) {
  const rol = store.state.usuario.rol;

  // Verifica si la ruta coincide con el rol del usuario
  if (
    (rol === "Administrador" &&
      to.matched.some((record) => record.meta.administrador)) ||
    (rol === "root" && to.matched.some((record) => record.meta.root)) ||
    (rol === "Vigilante" &&
      to.matched.some((record) => record.meta.vigilante)) ||
    (rol === "Cliente" && to.matched.some((record) => record.meta.cliente)) ||
    (rol === "APITAB" && to.matched.some((record) => record.meta.apitab)) ||
    (rol === "Agencia" && to.matched.some((record) => record.meta.agencia)) ||
    (rol === "PSP" && to.matched.some((record) => record.meta.psp)) ||
    (rol === "Microsip" && to.matched.some((record) => record.meta.microsip)) ||
    (rol === "OPIP" && to.matched.some((record) => record.meta.opib)) ||
    (rol === "Lectura" && to.matched.some((record) => record.meta.visor)) ||
    (rol === "Soporte" && to.matched.some((record) => record.meta.soporte)) ||
    (rol === "Operaciones" &&
      to.matched.some((record) => record.meta.operaciones)) ||
    (rol === "Mesa de control" &&
      to.matched.some((record) => record.meta.mesa_control))
  ) {
    next();
  } else {
    next({ name: "login" });
  }
}
export default router;
