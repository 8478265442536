<template>
  <div>
    <loading-overlay :overlay="loading"></loading-overlay>

    <h1>Tickets {{ this.$route.params.estatus }}</h1>
    <v-dialog v-model="dialogEvidencia" max-width="500" persistent>
      <v-card>
        <v-card-title class="headline">Evidencia</v-card-title>
        <v-card-text>
          <v-img :src="evidenciaSeleccionada" max-width="100%"></v-img>
          <!-- Mostrar la imagen -->
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogEvidencia = false">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogUpdateEstatus"
      transition="dialog-bottom-transition"
      max-width="650"
    >
      <v-card>
        <v-toolbar color="primary" dark>Atender ticket</v-toolbar>
        <v-card-text class="mt-5">
          <h4 class="mb-3">Actualizar el estatus del ticket</h4>
          <v-form v-model="isFormValidAtention" ref="form" class="my-4">
            <v-select
              :rules="[rules.required]"
              v-model="estatus"
              outlined
              dense
              :items="['En proceso', 'Resuelto', 'Cerrado']"
              label="Estatus"
            ></v-select>
          </v-form>
        </v-card-text>

        <v-card-actions class="justify-end">
          <v-btn
            :loading="loading"
            @click="actualizarEstatus"
            :disabled="!isFormValidAtention"
            >Guardar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogAtencion"
      transition="dialog-bottom-transition"
      max-width="650"
    >
      <v-card>
        <v-toolbar color="primary" dark></v-toolbar>
        <v-card-text class="mt-5">
          <h4 class="mb-3">Agrega comentarios al ticket</h4>
          <v-form v-model="isFormValid" ref="form" class="my-4">
            <v-text-field
              :rules="[rules.required]"
              v-model="asunto"
              outlined
              dense
              label="Asunto"
            ></v-text-field>
            <v-textarea
              :rules="[rules.required]"
              v-model="descripcion"
              outlined
              dense
              label="Descripción"
            ></v-textarea>
          </v-form>
        </v-card-text>

        <v-card-actions class="justify-end">
          <v-btn :loading="loading" @click="atenderTicket" :disabled="!isFormValid"
            >Guardar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-layout class="mt-10">
      <v-row>
        <v-col cols="8">
          <material-card icon="confirmation_number" class="px-5 py-3">
            <v-data-table
              :headers="headers"
              :items="tickets"
              dense
              class="custom-table-header pointer-cursor mt-3"
              item-value="id"
              @click:row="handleRowClick"
            >
              <template v-slot:item.folio_ticket="{ item }">
                <td style="font-size: 12px; color: gray; font-weight: bold">
                  Ticket #{{ item.folio_ticket }}
                </td>
              </template>
              <template v-slot:item.solicitante="{ item }">
                <td style="font-size: 12px; color: gray; font-weight: bold">
                  {{ item.solicitante.email }}
                </td>
              </template>

              <template v-slot:item.tema="{ item }">
                <td style="font-size: 12px; color: black; font-weight: bold">
                  {{ item.tema }}
                </td>
              </template>
              <template v-slot:item.prioridad="{ item }">
                <td style="font-size: 12px; color: black; font-weight: bold">
                  {{ item.prioridad }}
                </td>
              </template>
              <template v-slot:item.descripcion="{ item }">
                <td style="font-size: 12px; color: gray">
                  {{ item.descripcion }}
                </td>
              </template>

              <!-- Aquí agregamos los chips de estatus -->
              <template v-slot:item.estatus="{ item }">
                <td>
                  <v-chip x-small :color="getStatusColor(item.estatus)" dark>
                    {{ item.estatus }}
                  </v-chip>
                </td>
              </template>

              <template v-slot:item.opciones="{ item }">
                <td class="text-right">
                  <v-menu bottom left rounded="lg" :nudge-width="150">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>more_horiz</v-icon>
                      </v-btn>
                    </template>

                    <v-list dense>
                      <v-list-item @click="mostrarDialogs('atencion', item)" link>
                        <v-list-item-title>Atender ticket</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="mostrarDialogs('update_ticket', item)" link>
                        <v-list-item-title>Actualizar estatus</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        v-if="item.evidencia"
                        @click="mostrarEvidencia(item.evidencia)"
                        link
                      >
                        <v-list-item-title>Ver evidencia</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
              </template>
            </v-data-table>
          </material-card>
        </v-col>
        <v-col cols="4">
          <v-card class="pe-3">
            <v-card-title style="color: dimgrey">
              <h5 v-if="!ticket">Ningun ticket seleccionado</h5>
              <h5 v-else>Ticket #{{ ticket.folio_ticket }} - {{ ticket.asunto }}</h5>
            </v-card-title>
            <v-card-subtitle v-if="ticket">{{ ticket.tema }}</v-card-subtitle>
            <v-divider></v-divider>
            <v-timeline v-if="ticket" dense class="p-4">
              <v-timeline-item
                v-for="(item, index) in ticket.logs"
                :key="index"
                class="mb-4"
                :color="getStatusColor(item.estatus)"
                small
              >
                <v-row class="" justify="space-between">
                  <v-col cols="7">
                    <span style="font-size: 20px; font-weight: bold">
                      {{ item.titulo }}</span
                    >
                    <p style="font-size: 12px; color: gray">{{ item.descripcion }}</p>
                  </v-col>
                  <v-col class="text-right" cols="5">
                    <span style="font-size: 13px; color: gray">
                      {{ moment(item.fecha).calendar() }}
                    </span>
                  </v-col>
                </v-row>
              </v-timeline-item>
            </v-timeline>
          </v-card>
        </v-col>
      </v-row>
    </v-layout>
  </div>
</template>

<script>
import {
  addLogs,
  allTickets,
  getTicketsEstatus,
  getTicketsId,
  updateEstatus,
} from "../../api/endpoints";
import LoadingOverlay from "../components/Loading.vue";
import MaterialCard from "../components/MaterialCard.vue";
import moment from "moment";

export default {
  components: {
    LoadingOverlay,
    MaterialCard,
  },
  data() {
    return {
      headers: [
        { text: "Folio", value: "folio_ticket" },
        { text: "Solicitante", value: "solicitante" },
        { text: "Descripción", value: "descripcion" },
        { text: "Prioridad", value: "prioridad" },
        { text: "Estatus", value: "estatus" },

        { text: "", value: "opciones" },
      ],
      loading: false,
      isFormValid: false,
      isFormValidAtention: false,

      asunto: "",
      descripcion: "",
      estatus: "",
      dialogUpdateEstatus: false,
      tickets: [],
      moment,
      ticket: null,
      ticket_atencion: null,
      dialogAtencion: false,
      evidenciaSeleccionada: null,
      dialogEvidencia: false,
      rules: {
        required: (value) => !!value || "Este campo es requerido",
      },
    };
  },
  created() {
    this.getTickets();
  },
  methods: {
    async getTickets() {
      this.loading = true;
      allTickets(this.$route.params.estatus)
        .then((e) => {
          this.tickets = e;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    mostrarDialogs(type, item) {
      this.ticket_atencion = item;
      switch (type) {
        case "atencion":
          this.dialogAtencion = true;
          break;
        case "update_ticket":
          this.dialogUpdateEstatus = true;
          break;
      }
    },
    async atenderTicket() {
      this.loading = true;
      const datos = {
        titulo: this.asunto,
        descripcion: this.descripcion,
      };
      addLogs(datos, this.ticket_atencion._id)
        .then(() => {
          this.dialogAtencion = false;
          this.asunto = "";
          this.descripcion = "";
          this.getTicketById(this.ticket_atencion._id);

          this.ticket_atencion = [];
          this.getTickets();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async actualizarEstatus() {
      this.loading = true;

      updateEstatus(this.estatus, this.ticket_atencion._id)
        .then(() => {
          this.dialogUpdateEstatus = false;
          this.estatus = "";
          this.getTickets();
          this.getTicketById(this.ticket_atencion._id);
          this.ticket_atencion = [];
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getStatusColor(estatus) {
      switch (estatus) {
        case "En proceso":
          return "blue"; // Color para "En proceso"
        case "Resuelto":
          return "green"; // Color para "Resuelto"
        case "Cerrado":
          return "black"; // Color para "Cerrado"
        default:
          return "red"; // Color por defecto para estatus desconocidos
      }
    },
    handleRowClick(item) {
      this.ticket = item;
    },

    async getTicketById(ticketId) {
      this.loading = true;

      getTicketsId(ticketId) // Asume que tienes un método que obtiene un ticket por ID
        .then((updatedTicket) => {
          this.ticket = updatedTicket; // Actualiza el ticket en la vista
          console.log(this.ticket);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    mostrarEvidencia(evidencia) {
      this.evidenciaSeleccionada = evidencia; // Establecer la evidencia seleccionada
      this.dialogEvidencia = true; // Mostrar el diálogo
    },
  },
};
</script>

<style>
.pointer-cursor {
  cursor: pointer; /* Aplica el cursor de manito */
}
</style>
