import axios from "axios";
let token =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9eyJuYW1lIjoiQVBJIE1pY3Jvc2lwIiwiaWF0IjoxNTE2MjM5MDIyLCJlbXByZXNhIjoiQ0lDU0EvR01HL0FNTC9BUlJFTk1BUSJ9.pkM2iEDxllT4GIFe4tZ61p-8zpI1hQZzvTW64tMHxP8";
let configuracion = {
  headers: {
    Authorization: `Bearer ` + token,
  },
};
export default {
  mostrarTotalCobranzaDollar() {
    return axios.get(
      "https://api-microsip.ddns.net/api_microsip/amls/totalCobranzaDollar",
      configuracion
    );
  },
  mostrarTotalCobranzaPesos() {
    return axios.get(
      "https://api-microsip.ddns.net/api_microsip/amls/totalCobranzaPesos",
      configuracion
    );
  },
  mostrarCotizacionesPendientes(datos) {
    return axios.put(
      "https://api-microsip.ddns.net/api_microsip/amls/cotizaciones_pendientes",
      datos,
      configuracion
    );
  },
  mostrarCobrosMesCliente(datos) {
    return axios.put(
      "https://api-microsip.ddns.net/api_microsip/amls/listaCobrosMesCliente",
      datos,
      configuracion
    );
  },
  mostrarPagosProveedores(datos) {
    return axios.put(
      "https://api-microsip.ddns.net/api_microsip/amls/listPagosProveedores",
      datos,
      configuracion
    );
  },
  mostrarListaCobranzaDollar() {
    return axios.get(
      "https://api-microsip.ddns.net/api_microsip/amls/listCobranzaDollar",
      configuracion
    );
  },
  mostrarListaCobranzaPesos() {
    return axios.get(
      "https://api-microsip.ddns.net/api_microsip/amls/listCobranzaPesos",
      configuracion
    );
  },
  mostrarListaCotizacionesPendientesDet() {
    return axios.put(
      "https://api-microsip.ddns.net/api_microsip/amls/cotizaciones_pendientes_det",
      datos,
      configuracion
    );
  },
  mostrarTotalesCobrosRealizados(datos) {
    return axios.put(
      "https://api-microsip.ddns.net/api_microsip/amls/totalCobrosRealizados",
      datos,
      configuracion
    );
  },
  mostrarTotalesPagosRealizados(datos) {
    return axios.put(
      "https://api-microsip.ddns.net/api_microsip/amls/totalPagosRealizados",
      datos,
      configuracion
    );
  },
};
