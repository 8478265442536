<template>
  <div>
    <v-layout justify-center>
      <v-flex xs12 sm8 md6 lg4 x14>
        <v-container>
          <v-row justify="center">
            <v-col cols="auto">
              <v-img
                src="../../public/img/apitab-color.png"
                width="350px"
                height="auto"
                contain
              >
              </v-img>
            </v-col>
          </v-row>
        </v-container>

        <br />

        <v-container class="justify-center align-center">
          <v-card class="elevation-10" color="#f5f5f5" outlined rounded="lg">
            <v-card-title class="text-h5 text-center font-weight-bold primary--text">
              Iniciar sesión
            </v-card-title>

            <v-form @submit.prevent="ingresar">
              <v-card-text>
                <v-text-field
                  v-model="email"
                  type="email"
                  autofocus
                  color="primary"
                  label="Correo"
                  outlined
                  dense
                  required
                ></v-text-field>
                <v-text-field
                  v-model="password"
                  :type="show1 ? 'text' : 'password'"
                  color="primary"
                  label="Contraseña"
                  :append-icon="show1 ? 'visibility' : 'visibility_off'"
                  @click:append="show1 = !show1"
                  outlined
                  dense
                  required
                ></v-text-field>
              </v-card-text>

              <v-card-actions class="px-4">
                <v-btn
                  type="submit"
                  block
                  :loading="loading"
                  color="primary"
                  class="white--text font-weight-bold"
                >
                  Ingresar
                </v-btn>
              </v-card-actions>

              <v-card-text class="text-center pt-3">
                <v-btn text small color="secondary" @click="forgotPassword"
                  >¿Olvidaste tu contraseña?</v-btn
                >
              </v-card-text>
            </v-form>

            <v-divider></v-divider>

            <!-- <v-card-actions class="text-center pt-3">
            <v-btn text color="primary">Solicitar una cuenta</v-btn>
          </v-card-actions> -->
          </v-card>
        </v-container>
      </v-flex>
    </v-layout>
    <v-dialog v-model="qrInfoDialog" max-width="600">
      <v-card>
        <v-card-title class="headline">Información</v-card-title>
        <v-card-text>
          <div class="d-flex justify-center">
            <v-progress-circular
              :size="50"
              color="primary"
              indeterminate
              v-if="loading_camera"
            ></v-progress-circular>
          </div>
          <qrcode-stream
            @decode="onDecode"
            v-if="mostrarCamara"
            :camera="camera"
            @init="onInit"
          >
          </qrcode-stream>

          <div v-if="info.modelo">
            <v-card-subtitle v-if="info.data.counter"
              >Acceso de vehiculos personal : VP-000{{
                info.data.counter
              }}</v-card-subtitle
            >
            <v-card-subtitle v-if="info.data.folio"
              >Solicitud de acceso a personal : CG-000{{
                info.data.folio
              }}</v-card-subtitle
            >
            <v-card-subtitle v-if="info.data.folio_solicitud"
              >Solicitud de acceso unica : SU-000{{
                info.data.folio_solicitud
              }}</v-card-subtitle
            >
            <v-divider></v-divider>

            <v-card-text>
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Agencia</v-list-item-title>
                    <v-list-item-subtitle>{{
                      info.data.agencia.nombre
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Embarcación</v-list-item-title>
                    <v-list-item-subtitle>{{
                      info.data.embarcacion.nombre
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-divider></v-divider>
              <v-card-subtitle class="">Autorizaciones</v-card-subtitle>
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title style="font-weight: bold">
                      {{ info.data.autorizacion_1.nombre_completo }}</v-list-item-title
                    >
                    <v-list-item-subtitle>
                      <strong>Correo:</strong> {{ info.data.autorizacion_1.correo }}
                      <br />
                      <strong>Fecha de Aprobación:</strong>
                      {{ info.data.autorizacion_1.fecha_aprobacion }} <br />
                      <strong>Autorizado:</strong>
                      <v-icon v-if="info.data.autorizacion_1.autorizado" color="green"
                        >check</v-icon
                      >
                      <v-icon v-else color="red">cancel</v-icon>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title style="font-weight: bold">
                      {{ info.data.autorizacion_2.nombre_completo }}</v-list-item-title
                    >
                    <v-list-item-subtitle>
                      <strong>Correo:</strong> {{ info.data.autorizacion_2.correo }}
                      <br />
                      <strong>Fecha de Aprobación:</strong>
                      {{ info.data.autorizacion_2.fecha_aprobacion }} <br />
                      <strong>Autorizado:</strong>
                      <v-icon v-if="info.data.autorizacion_2.autorizado" color="green"
                        >check</v-icon
                      >
                      <v-icon v-else color="red">cancel</v-icon>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
            <v-divider></v-divider>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="closeQrInfoDialog">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-fab-transition>
          <v-btn
            color="primary"
            v-bind="attrs"
            v-on="on"
            large
            dark
            bottom
            @click="showQrInfoDialog"
            left
            class="v-btn--example"
          >
            Escaner documento<v-icon class="ml-3">qr_code_scanner</v-icon>
          </v-btn>
        </v-fab-transition>
      </template>
      <span
        >Haz clic aquí para escanear y validar los códigos QR de las solicitudes.</span
      >
    </v-tooltip>
  </div>
</template>

<script>
import axios from "axios";
import swal from "sweetalert";
import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from "vue-qrcode-reader";

export default {
  components: {
    QrcodeStream,
  },
  data() {
    return {
      email: "",
      password: "",
      loading: false,
      dialog: false,
      errorM: "",
      snackbar: false,
      show1: false,
      mostrarCamara: false,
      camera: "auto",
      loading_camera: false,
      showScanConfirmation: false,
      qrInfoDialog: false,
      empresa: "",
      info: [],
      nombre: "",
      direccion: "",
      rol: "",
      cargo: "",
      telefono: "",
      estado: "",
    };
  },
  methods: {
    ingresar() {
      this.loading = true;

      axios
        .post("usuario/login", { email: this.email, password: this.password })
        .then((respuesta) => {
          return respuesta.data;
        })
        .then((data) => {
          this.$store.dispatch("guardarToken", data.tokenReturn);
          console.log(data.user.rol);
          if (data.user.rol == "Vigilante") {
            this.$router.push({ name: "gestion_acceso" });
          } else {
            this.$router.push({ name: "home" });
          }
        })
        .catch((error) => {
          this.errorM = null;
          if (error.response.status == 404) {
            this.$notify({
              title: "Error!",
              text: "El usuario no existe, favor de verificar sus datos",
              type: "error",
            });
          } else {
            this.errorM = "Ocurrio un error con el servidor";
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    guardar() {
      let me = this;
      //Nuevo
      axios
        .post("usuario/addPsp", {
          nombre: this.nombre,
          rol: "PSP",
          estado: 0,
          empresa: this.empresa,
          email: this.email,
          direccion: this.direccion,
          telefono: this.telefono,
          password: this.password,
          cargo: this.cargo,
        })
        .then(function (response) {
          swal(
            "Datos agregados con exito",
            "Para que tu solicitud sea aprobada favor de enviar por correo los siguientes archivos:",
            "success"
          );
          me.close();
          me.limpiar();
        })
        .catch(function (error) {
          me.close();
          me.limpiar();
          swal("Error", "Verique los datos ingreasados" + error, "error");
        });
    },
    forgotPassword() {
      this.$router.push({ name: "forgot_password" });
    },
    showQrInfoDialog() {
      this.info = [];
      this.qrInfoDialog = true;
      this.mostrarCamara = true;
    },
    closeQrInfoDialog() {
      this.qrInfoDialog = false;
      this.mostrarCamara = false;
    },
    limpiar() {
      (this.nombre = ""),
        (this.email = ""),
        (this.empresa = ""),
        (this.cargo = ""),
        (this.telefono = ""),
        (this.direccion = ""),
        (this.contraseña = "");
    },
    close() {
      this.dialog = false;
    },
    async onInit(promise) {
      try {
        this.loading_camera = true;
        await promise;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading_camera = false;
        this.showScanConfirmation = this.camera === "off";
      }
    },

    async onDecode(content) {
      this.result = content;
      //this.mostrarRegistros = true;
      //this.pause();
      console.log(content);
      this.getDataQr(content);
      //await this.timeout(500);
      //this.buttonScan = false;
      this.mostrarCamara = false;
    },

    async getDataQr(_id) {
      this.loading_camera = true;
      await axios
        .get("solicitudUnica/validar_qr/" + _id)
        .then((e) => {
          this.info = e.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading_camera = false;
        });
    },
  },
};
</script>
<style scoped>
.v-card {
  border-radius: 20px;
}
.v-card-title {
  padding-top: 24px;
}
.v-btn {
  border-radius: 8px;
}

.v-btn--example {
  bottom: 0;
  position: absolute;
  margin: 0 0 16px 16px;
}
.headline {
  font-weight: bold;
}
</style>
