<template>
  <v-container fluid fill-height>
    <v-row align="center" justify="center">
      <v-col cols="12" md="6" lg="4">
        <v-card class="elevation-12 pa-5">
          <v-card-title class="text-center">
            <h3 class="font-weight-bold">Restablecer Contraseña</h3>
          </v-card-title>

          <v-card-text>
            <v-form v-model="valid" ref="form" lazy-validation>
              <v-text-field
                v-model="email"
                label="Correo electrónico"
                :rules="emailRules"
                outlined
                required
              ></v-text-field>

              <v-btn
                :disabled="!valid"
                color="primary"
                class="ma-2"
                :loading="loading"
                block
                @click="requestReset"
              >
                Enviar
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { forgotPassword } from "../../api/endpoints";

export default {
  data() {
    return {
      email: "",
      valid: false,
      loading: false,
      emailRules: [
        (v) => !!v || "El correo electrónico es requerido",
        (v) => /.+@.+\..+/.test(v) || "Ingrese un correo electrónico válido",
      ],
    };
  },
  methods: {
    async requestReset() {
      console.log("first");
      if (this.$refs.form.validate()) {
        this.loading = true;
        forgotPassword(this.email)
          .then(() => {
            swal(
              "¡Solicitud Enviada!",
              "Se ha enviado un correo con un enlace para restablecer tu contraseña. Por favor, revisa tu bandeja de entrada.",
              "success"
            );
          })
          .catch((err) => {
            swal(
              "Error",
              "No se pudo encontrar una cuenta asociada a ese correo electrónico. Por favor, verifica e intenta nuevamente.",
              "error"
            );
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>

<style scoped>
.v-card {
  border-radius: 12px;
}
</style>
