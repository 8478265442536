<template>
  <v-container fluid fill-height>
    <v-row align="center" justify="center">
      <v-col cols="12" md="6" lg="4">
        <v-card class="elevation-12 pa-5">
          <v-card-title class="text-center">
            <h3 class="font-weight-bold">Crear Nueva Contraseña</h3>
          </v-card-title>

          <v-card-text>
            <v-form v-model="valid" ref="form" @submit.prevent="resetPassword">
              <!-- Campo para la nueva contraseña -->
              <v-text-field
                v-model="password"
                label="Nueva Contraseña"
                :rules="passwordRules"
                :type="show1 ? 'text' : 'password'"
                :append-icon="show1 ? 'visibility_off' : 'visibility'"
                @click:append="show1 = !show1"
                outlined
                required
              ></v-text-field>

              <!-- Campo para confirmar la contraseña -->
              <v-text-field
                v-model="confirmPassword"
                label="Confirmar Contraseña"
                :rules="confirmPasswordRules"
                :type="show2 ? 'text' : 'password'"
                :append-icon="show2 ? 'visibility_off' : 'visibility'"
                @click:append="show2 = !show2"
                outlined
                required
              ></v-text-field>

              <v-btn
                :disabled="!valid"
                color="primary"
                class="ma-2"
                :loading="loading"
                block
                @click="resetPassword"
              >
                Restablecer Contraseña
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import { resetPassword } from "../../api/endpoints";

export default {
  data() {
    return {
      password: "",
      confirmPassword: "",
      show2: false,
      show1: false,
      showPassword: false,
      valid: false,
      loading: false,
      passwordRules: [
        (v) => !!v || "La contraseña es requerida",
        (v) => v.length >= 6 || "La contraseña debe tener al menos 6 caracteres",
      ],
      confirmPasswordRules: [
        (v) => !!v || "La confirmación de la contraseña es requerida",
        (v) => v === this.password || "Las contraseñas no coinciden",
      ],
    };
  },
  methods: {
    toggleShowPassword() {
      this.showPassword = !this.showPassword;
    },
    async resetPassword() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        const token = this.$route.query.token;

        resetPassword(token, this.password)
          .then(() => {
            this.$notify({
              title: "¡Éxito!",
              text: "La operación se completo con éxito",
              type: "success",
            });
            this.$router.push("/login");
          })
          .catch((err) => {
            this.$notify({
              title: "Error",
              text: "Revise con el administrador",
              type: "error",
            });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>

<style scoped>
.v-card {
  border-radius: 12px;
}

.v-btn {
  font-weight: bold;
}

.v-card-title h3 {
  color: #1e88e5;
}

.v-card-text {
  margin-top: 20px;
}
</style>
