<template>
  <div>
    <h1 style="" class="subheading grey--text">Bienvenidos</h1>
  </div>
  <!-- <div>
    <h1 style="" class="subheading grey--text">Dashboard <v-btn icon color="indigo" @click="refresh()">
        <v-icon>cached</v-icon>
      </v-btn></h1>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-dialog transition="dialog-bottom-transition" max-width="1200" v-model="dialogListaCotizacionesDet">
      <template>
        <v-card>
          <v-toolbar color="dark" dark>Cotizaciones Pendientes</v-toolbar>
          <v-card-text>
            <div class="text-h2 pa-12">
              <v-data-table dense :headers="headers4" :items="cotizaciones_pendientes_det" item-key="name"
                class="elevation-1">
                <template v-slot:item.FOLIO="{ item }">
                  <div class="nombre-item">
                    <span class="font-weight-bold" style="font-size: 10px">{{
      item.FOLIO
    }}</span>
                  </div>
                </template>

                <template v-slot:item.NOMBRE="{ item }">
                  <div class="nombre-item">
                    <span class="font-weight-bold" style="font-size: 10px">{{
      item.NOMBRE
    }}</span>
                  </div>
                </template>

                <template v-slot:item.FECHA="{ item }">
                  <div class="nombre-item">
                    <span class="font-weight-bold" style="font-size: 10px">{{
      item.FECHA
    }}</span>
                  </div>
                </template>

                <template v-slot:item.CLIENTE="{ item }">
                  <div class="nombre-item">
                    <span class="font-weight-bold" style="font-size: 10px">{{
      item.CLIENTE
    }}</span>
                  </div>
                </template>
              </v-data-table>


            </div>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text @click="dialogListaCotizacionesDet = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    <v-dialog transition="dialog-bottom-transition" max-width="1200" v-model="dialogListaCobranzaDollar">

      <template>
        <v-card>
          <v-toolbar color="dark" dark>Totales de Clientes con facturas vencidas</v-toolbar>
          <v-card-text>
            <div class="text-h2 pa-12">
              <v-data-table dense :headers="headers3" :items="cobranza_mes_dollar_det" item-key="name"
                class="elevation-1">
                <template v-slot:item.nombre="{ item }">
                  <div class="nombre-item">
                    <span class="font-weight-bold" style="font-size: 13px">{{
      item.nombre
    }}</span>
                  </div>
                </template>

                <template v-slot:item.valor="{ item }">
                  <div class="moneda-item-dollar">
                    <span class="font-weight-bold" style="font-size: 13px;text-align:center">{{
      formatCurrency(item.valor) }}</span>

                  </div>
                </template>

                <template v-slot:item.TOTAL_IMPORTE_COBRADO="{ item }">
                  <div class="moneda-total-item">
                    <span class="font-weight-bold" style="font-size: 13px">{{ formatCurrency(item.valor)

                      }}</span>
                  </div>
                </template>
              </v-data-table>

            </div>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text @click="dialogListaCobranzaDollar = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    <v-dialog transition="dialog-bottom-transition" max-width="1200" v-model="dialogListaCobranzaPesos">

      <template>
        <v-card>
          <v-toolbar color="dark" dark>Totales de Clientes con facturas vencidas</v-toolbar>
          <v-card-text>
            <div class="text-h2 pa-12">
              <v-data-table dense :headers="headers3" :items="cobranza_mes_pesos_det" item-key="name"
                class="elevation-1">
                <template v-slot:item.nombre="{ item }">
                  <div class="nombre-item">
                    <span class="font-weight-bold" style="font-size: 13px">{{
      item.nombre
    }}</span>
                  </div>
                </template>

                <template v-slot:item.valor="{ item }">
                  <div class="moneda-item">
                    <span class="font-weight-bold" style="font-size: 13px;text-align:center">{{
      formatCurrency(item.valor) }}</span>

                  </div>
                </template>

                <template v-slot:item.TOTAL_IMPORTE_COBRADO="{ item }">
                  <div class="moneda-total-item">
                    <span class="font-weight-bold" style="font-size: 13px">{{ formatCurrency(item.valor) }}</span>
                  </div>
                </template>
              </v-data-table>

            </div>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text @click="dialogListaCobranzaPesos = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    <v-row class="cobranza-card">
      <v-col cols="12" sm="6" md="3">
        <v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y
          min-width="auto">

          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="fecha_inicio" label="Periodo inicial" readonly v-bind="attrs"
              v-on="on"></v-text-field>
          </template>
          <v-date-picker v-model="fecha_inicio" @input="actualizarDatos()"></v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="12" sm="6" md="3">
        <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y
          min-width="auto">

          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="fecha_final" label="Periodo final" readonly v-bind="attrs" v-on="on"></v-text-field>
          </template>
          <v-date-picker v-model="fecha_final" @input="actualizarDatosMenu2()"></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row>


      <v-col cols="12" sm="4" md="4" lg="4">


        <v-card full-width class="cobranza-card">
          <v-sheet class="v-sheet--offset mx-auto" color="success" elevation="12">
            <div class="text-h6 font-weight-light white--text text-center">
              Pendiente por Cobrar
            </div>
          </v-sheet>
          <br />
          <v-card-text class="pt-0">
            <div class="subheading font-weight-light grey--text text-center">


              <h1> {{ formatCurrency(this.cobranza_dollar) }}</h1>

            </div>
            <v-divider class="my-2"></v-divider>
            <v-btn @click="mostrarCobranzaDet" text icon color="red lighten-2">
              <v-icon>grid_view</v-icon>
            </v-btn>
            <span class="text-caption grey--text">Moneda en Dolares Americanos</span>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="4" md="4" lg="4">
        <v-card full-width>
          <v-sheet class="v-sheet--offset mx-auto" color="success" elevation="12">
            <div class="text-h6 font-weight-light white--text" style="text-align: center">
              Pendiente por Cobrar
            </div>
          </v-sheet>
          <br />
          <v-card-text class="pt-0">
            <div class="subheading font-weight-light grey--text" style="text-align: center">
              <h1>{{ formatCurrency(this.cobranza_pesos) }}</h1>
            </div>
            <v-divider class="my-2"></v-divider>
            <v-btn @click="mostrarCobranzaPesosDet" text icon color="red lighten-2">
              <v-icon>grid_view</v-icon>
            </v-btn>
            <span class="text-caption grey--text">Moneda en Pesos MX</span>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="4" md="4" lg="4">
        <v-card full-width>
          <v-sheet class="v-sheet--offset mx-auto" color="success" elevation="12">
            <div class="text-h6 font-weight-light white--text" style="text-align: center">
              Total de Cotizaciones Pendientes
            </div>
          </v-sheet>
          <br />
          <v-card-text class="pt-0">
            <div class="subheading font-weight-light grey--text" style="text-align: center">
              <h1>{{ cotizaciones_pendientes }}</h1>
            </div>
            <v-divider class="my-2"></v-divider>
            <v-btn @click="mostrarCotizacionesPendientesDet" text icon color="red lighten-2">
              <v-icon>grid_view</v-icon>
            </v-btn>
            <span class="text-caption grey--text"></span>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" md="6" lg="6">
        <v-card color="primary" class="mx-auto text-center" full-width>
          <v-card-text>
            <div class="text-h6 " style="color: rgb(182, 182, 182); font-weight:bold">
              Cobros Realizados en el mes <br>
              <div v-if="this.cobrosTotales[0]">
                MXN: {{ formatCurrency(parseFloat(this.cobrosTotales[1].TOTAL_IMPORTE_IMPUESTO)) }}
              </div>
              <div v-if="this.cobrosTotales[1]">
                USD: {{ formatCurrency(parseFloat(this.cobrosTotales[0].TOTAL_IMPORTE_IMPUESTO)) }}

              </div>
            </div>
          </v-card-text>
          <v-card-text>
            <v-sheet color="rgba(0, 0, 0, .12)">
              <v-data-table dense :headers="headers" :items-per-page="100" :items="cobranza_del_mes" item-key="name"
                class="elevation-1">

                <template v-slot:item.NOMBRE="{ item }">
                  <div class="nombre-item">
                    <span class="font-weight-bold" style="font-size: 10px">{{
      item.NOMBRE
    }}</span>
                  </div>
                </template>

                <template v-slot:item.MONEDA="{ item }">
                  <div class="moneda-item" v-if="item.MONEDA == 'Moneda nacional'">
                    <span class="font-weight-bold" style="font-size: 10px">MXN</span>

                  </div>
                  <div class="moneda-item-dollar" v-else>
                    <span class="font-weight-bold" style="font-size: 10px">USD</span>

                  </div>

                </template>

                <template v-slot:item.TOTAL_IMPORTE_COBRADO="{ item }">
                  <div class="moneda-total-item">
                    <span class="font-weight-bold" style="font-size: 13px">{{
      formatCurrency(parseFloat(item.TOTAL_IMPORTE_IMPUESTO))
    }}</span>
                  </div>
                </template>
              </v-data-table>
            </v-sheet>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="6" lg="6">
        <v-card color="primary" class="mx-auto text-center" full-width>
          <v-card-text>
            <div class="text-h6 " style="color: rgb(182, 182, 182); font-weight:bold">
              Pagos realizados en el mes <br>
              <div v-if="this.pagosTotales[0]">
                MXN: {{ formatCurrency(parseFloat(this.pagosTotales[1].TOTAL_IMPORTE_IMPUESTO)) }}
              </div>
              <div v-if="this.pagosTotales[1]">
                USD: {{ formatCurrency(parseFloat(this.pagosTotales[0].TOTAL_IMPORTE_IMPUESTO)) }}

              </div>
            </div>
          </v-card-text>
          <v-card-text>
            <v-sheet color="rgba(0, 0, 0, .12)">
              <v-data-table dense :headers="headers2" :items-per-page="100" :items="pagos_del_mes" item-key="name"
                class="elevation-1">

                <template v-slot:item.NOMBRE="{ item }">
                  <div class="nombre-item">
                    <span class="font-weight-bold" style="font-size: 10px">{{
                      item.NOMBRE
                      }}</span>
                  </div>
                </template>

                <template v-slot:item.MONEDA="{ item }">
                  <div class="moneda-item" v-if="item.MONEDA == 'Moneda nacional'">
                    <span class="font-weight-bold" style="font-size: 10px">MXN</span>

                  </div>
                  <div class="moneda-item-dollar" v-else>
                    <span class="font-weight-bold" style="font-size: 10px">USD</span>

                  </div>

                </template>

                <template v-slot:item.TOTAL_IMPORTE_IMPUESTO="{ item }">
                  <div class="moneda-total-pagado-item">
                    <span class="font-weight-bold" style="font-size: 13px">{{
                      formatCurrency(parseFloat(item.TOTAL_IMPORTE_IMPUESTO))
                      }}</span>
                  </div>
                </template>
              </v-data-table>
            </v-sheet>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div> -->
</template>

<script>
import api from "../api/api_microsip";
import ApexChart from "vue-apexcharts";
import GraficaDona from "../views/components/GraficaDona.vue";

export default {
  components: {
    ApexChart,
    GraficaDona,
  },

  data() {
    return {
      date: "",
      menu: false,
      modal: false,
      solicitudUnicasTotal: "",
      cambiosGuardiaTotal: "",
      vehiculosPersonalTotal: "",
      totalPasajerosEmbarcados: [],
      embarcacionSolicitudesUnicas: [],
      cobranza_dollar: 0,
      dialogListaCobranzaDollar: false,
      dialogListaCobranzaPesos: false,
      dialogListaCotizacionesDet: false,
      cobranza_pesos: 0,
      cotizaciones_pendientes: 0,
      cobranza_del_mes: [],
      overlay: true,
      fecha_inicio: "",
      fecha_final: "",
      menu1: false,
      menu2: false,
      cobranza_mes_dollar_det: [],
      cobranza_mes_pesos_det: [],
      pagos_del_mes: [],
      cotizaciones_pendientes_det: [],
      pagosTotales: [],
      cobrosTotales: [],
      headers: [
        {
          text: "Cliente",
          align: "center",
          sortable: true,
          value: "NOMBRE",
        },
        {
          text: "Moneda",
          align: "center",
          sortable: true,
          value: "MONEDA",
        },
        {
          text: "Cobrado",
          align: "center",
          sortable: true,
          value: "TOTAL_IMPORTE_COBRADO",
        },
      ],
      headers2: [
        {
          text: "Proveedor",
          align: "center",
          sortable: true,
          value: "NOMBRE",
        },
        {
          text: "Moneda",
          align: "center",
          sortable: true,
          value: "MONEDA",
        },
        {
          text: "Pagado",
          align: "center",
          sortable: true,
          value: "TOTAL_IMPORTE_IMPUESTO",
        },
      ],
      headers3: [
        {
          text: "Cliente",
          align: "center",
          sortable: true,
          value: "nombre",
        },
        {
          text: "Cobrado",
          align: "center",
          sortable: true,
          value: "valor",
        },
      ],
      headers4: [
        {
          text: "Folio",
          align: "center",
          sortable: true,
          value: "FOLIO",
        },
        {
          text: "Cliente",
          align: "center",
          sortable: true,
          value: "NOMBRE",
        },

        {
          text: "Fecha",
          align: "center",
          sortable: true,
          value: "FECHA",
        },
        {
          text: "Descripción",
          align: "center",
          sortable: true,
          value: "CLIENTE",
        },
      ],
    };
  },
  created() {
    this.obtenerMesInicial();
    this.mostrarTotalCobranzaDollar();
    this.mostrarTotalCobranzaPesos();
    this.mostrarCotizacionPendientes();
    this.mostrarCobranzaMes();
    this.mostrarPagosMes();
    this.mostrarCobrosTotalesMes();
    this.mostrarPagosTotalesMes();
  },

  methods: {
    refresh() {
      this.mostrarTotalCobranzaDollar();
      this.mostrarTotalCobranzaPesos();
      this.mostrarCotizacionPendientes();
      this.mostrarCobranzaMes();
      this.mostrarPagosMes();
      this.mostrarCobrosTotalesMes();
      this.mostrarPagosTotalesMes();
    },

    obtenerMesInicial() {
      const fecha_actual = new Date();
      fecha_actual.setDate(1);

      let year = fecha_actual.getFullYear();
      let month = (fecha_actual.getMonth() + 1).toString().padStart(2, "0");
      let day = fecha_actual.getDate().toString().padStart(2, "0");

      let ultimoDiaDelMes = new Date(
        fecha_actual.getFullYear(),
        fecha_actual.getMonth() + 1,
        0
      );

      // Obtiene el año, mes y día del último día del mes
      let yearUltimoDia = ultimoDiaDelMes.getFullYear();
      let monthUltimoDia = (ultimoDiaDelMes.getMonth() + 1).toString().padStart(2, "0");
      let dayUltimoDia = ultimoDiaDelMes.getDate().toString().padStart(2, "0");

      // Formatea la fecha en el formato yyyy-mm-dd
      this.fecha_final = `${yearUltimoDia}-${monthUltimoDia}-${dayUltimoDia}`;
      this.fecha_inicio = `${year}-${month}-${day}`;
    },

    mostrarTotalCobranzaDollar() {
      this.overlay = true;
      const datos = {
        fecha_inicio: this.fecha_inicio,
        fecha_final: this.fecha_final,
      };
      api
        .mostrarTotalCobranzaDollar(datos)
        .then((e) => {
          this.cobranza_dollar = e.data.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.overlay = false;
        });
    },
    mostrarTotalCobranzaPesos() {
      this.overlay = true;
      api
        .mostrarTotalCobranzaPesos()
        .then((e) => {
          this.cobranza_pesos = e.data.data;
        })
        .finally(() => {
          this.overlay = false;
        });
    },
    mostrarCotizacionPendientes() {
      this.overlay = true;
      const datos = {
        fecha_inicio: this.fecha_inicio,
        fecha_final: this.fecha_final,
      };
      api
        .mostrarCotizacionesPendientes(datos)
        .then((e) => {
          console.log(e.data);
          this.cotizaciones_pendientes = e.data.data[0].TOTAL_REGISTROS;
        })
        .finally(() => {
          this.overlay = false;
        });
    },
    mostrarCobranzaMes() {
      this.overlay = true;

      const datos = {
        fecha_inicio: this.fecha_inicio,
        fecha_final: this.fecha_final,
      };
      api
        .mostrarCobrosMesCliente(datos)
        .then((e) => {
          this.cobranza_del_mes = e.data.data;
        })
        .finally(() => {
          this.overlay = false;
        });
    },
    mostrarPagosMes() {
      this.overlay = true;

      const datos = {
        fecha_inicio: this.fecha_inicio,
        fecha_final: this.fecha_final,
      };
      api
        .mostrarPagosProveedores(datos)
        .then((e) => {
          this.pagos_del_mes = e.data.data;
        })
        .finally(() => {
          this.overlay = false;
        });
    },
    mostrarCobrosTotalesMes() {
      this.overlay = true;
      const datos = {
        fecha_inicio: this.fecha_inicio,
        fecha_final: this.fecha_final,
      };
      api
        .mostrarTotalesCobrosRealizados(datos)
        .then((e) => {
          this.cobrosTotales = e.data.data;
        })
        .finally(() => {
          this.overlay = false;
        });
    },
    mostrarPagosTotalesMes() {
      this.overlay = true;
      const datos = {
        fecha_inicio: this.fecha_inicio,
        fecha_final: this.fecha_final,
      };
      api
        .mostrarTotalesPagosRealizados(datos)
        .then((e) => {
          this.pagosTotales = e.data.data;
        })
        .finally(() => {
          this.overlay = false;
        });
    },

    mostrarCobranzaDet() {
      this.overlay = true;
      this.dialogListaCobranzaDollar = true;
      api
        .mostrarListaCobranzaDollar()
        .then((e) => {
          var jsonArray = Object.entries(e.data.data).map(([nombre, valor]) => ({
            nombre,
            valor,
          }));
          this.cobranza_mes_dollar_det = jsonArray;
        })
        .finally(() => {
          this.overlay = false;
        });
    },

    mostrarCobranzaPesosDet() {
      this.overlay = true;

      api
        .mostrarListaCobranzaPesos()
        .then((e) => {
          var jsonArray = Object.entries(e.data.data).map(([nombre, valor]) => ({
            nombre,
            valor,
          }));
          this.cobranza_mes_pesos_det = jsonArray;
          this.dialogListaCobranzaPesos = true;
        })
        .finally(() => {
          this.overlay = false;
        });
    },

    mostrarCotizacionesPendientesDet() {
      this.overlay = true;
      const datos = {
        fecha_inicio: this.fecha_inicio,
        fecha_final: this.fecha_final,
      };
      this.dialogListaCotizacionesDet = true;
      api
        .mostrarListaCotizacionesPendientesDet(datos)
        .then((e) => {
          this.cotizaciones_pendientes_det = e.data.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.overlay = false;
        });
    },

    actualizarDatos() {
      this.menu1 = false;
      this.refresh();
    },

    actualizarDatosMenu2() {
      this.menu2 = false;
      this.refresh();
    },

    formatCurrency(value) {
      return value.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
  },
  mounted() {},
};
</script>

<style>
.nombre-item {
  /* Agrega estilos personalizados para el contenedor del nombre */
  /* Por ejemplo, ajustar margen, alineación, color de fondo, etc. */
  margin-bottom: 2px;
  margin-top: 2px;
  text-align: center;
  background-color: #f2f2f2;
  padding: 5px 10px;
  border-radius: 4px;
  /* Agrega otros estilos según tus preferencias */
}

.text-body-2 {
  /* Agrega estilos personalizados para el tamaño del texto body-2 */
  font-size: 3px;
  /* Agrega otros estilos según tus preferencias */
}

.moneda-item {
  margin-bottom: 2px;
  margin-top: 2px;
  text-align: center;
  background-color: #e3f2fd;
  padding: 5px 10px;
  border-radius: 4px;
  /* Agrega otros estilos según tus preferencias */
}

.moneda-item-dollar {
  margin-bottom: 2px;
  margin-top: 2px;
  text-align: center;
  background-color: #dbd79d;
  padding: 5px 10px;
  border-radius: 4px;
  /* Agrega otros estilos según tus preferencias */
}

.moneda-total-item {
  display: flex;
  align-items: center;
  background-color: #a8fdc8;
  padding: 5px 10px;
  border-radius: 4px;
  /* Agrega otros estilos según tus preferencias */
}

.moneda-total-pagado-item {
  display: flex;
  align-items: center;
  background-color: #eca6a9;
  padding: 5px 10px;
  border-radius: 4px;
  /* Agrega otros estilos según tus preferencias */
}

/* Estilos para el ícono de la moneda */
.money-icon {
  font-size: 16px;
  margin-right: 5px;
  /* Agrega otros estilos según tus preferencias */
}

/* Estilos para el texto de la moneda */
.text-body-2 {
  font-size: 12px;
  font-weight: bold;
  /* Agrega otros estilos según tus preferencias */
}
</style>
