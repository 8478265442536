<template>
  <v-layout>
    <v-flex>
      <div class="pb-10 d-flex align-center justify-space-between">
        <div>
          <h2>Tarifas Muelle Chiltepec</h2>
          <span style="font-size: 13px; color: gray">
            Todos los datos son obligatorios
          </span>
        </div>
      </div>
      <v-layout align-start>
        <v-flex>
          <v-overlay :value="loading">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
          <material-card icon="attach_money" title="" class="px-5 py-3">
            <v-row>
              <v-col sm="3" lg="3">
                <v-text-field
                  v-model="puerto_fijo"
                  prefix="$"
                  type="number"
                  label="Puerto Fijo"
                  value="00.00"
                ></v-text-field>
              </v-col>
              <v-col sm="3" lg="3">
                <v-text-field
                  prefix="$"
                  label="Puerto Variable"
                  type="number"
                  value="00.00"
                  v-model="puerto_variable"
                ></v-text-field>
              </v-col>
              <v-col sm="3" lg="3">
                <v-text-field
                  prefix="$"
                  label="Tarifa de atraque"
                  value="00.00"
                  type="number"
                  v-model="tarifa_atraque"
                ></v-text-field>
              </v-col>
              <v-col sm="3" lg="3">
                <v-text-field
                  v-model="muellaje"
                  prefix="$"
                  label="Muellaje"
                  type="number"
                  value="00.00"
                ></v-text-field>
              </v-col>
              <v-col sm="3" lg="3">
                <v-text-field
                  prefix="$"
                  type="number"
                  label="Cuota por pasajero"
                  value="00.00"
                  v-model="cuota_pasajero"
                ></v-text-field>
              </v-col>
              <v-col sm="3" lg="3">
                <v-text-field
                  prefix="$"
                  label="Cuota arribo y amarre"
                  value="00.00"
                  type="number"
                  v-model="cuota_arribo_amarre"
                ></v-text-field>
              </v-col>
              <v-col sm="3" lg="3">
                <v-text-field
                  prefix="$"
                  label="Movimiento interno"
                  type="number"
                  v-model="movimiento_interno"
                  value="00.00"
                ></v-text-field>
              </v-col>

              <v-col sm="3" lg="3">
                <v-text-field
                  prefix="$"
                  label="Asistencia al práctico"
                  type="number"
                  v-model="asitencia_practico"
                  value="00.00"
                ></v-text-field>
              </v-col>

              <v-col sm="12" lg="12">
                <v-btn @click="saveChanges" block color="primary">Guardar cambios</v-btn>
              </v-col>
            </v-row>
          </material-card>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import { guardarCambiosTarifas, obtenerTarifa } from "../../api/endpoints";
import LoadingOverlay from "../components/Loading.vue";
import MaterialCard from "../components/MaterialCard.vue";

export default {
  components: {
    LoadingOverlay,
    MaterialCard,
  },
  data() {
    return {
      puerto_variable: "00.00",
      puerto_fijo: "",
      tarifa_atraque: "",
      muellaje: "",
      cuota_pasajero: "",
      cuota_arribo_amarre: "",
      movimiento_interno: "",
      asitencia_practico: "",
      loading: false,
    };
  },
  created() {
    this.cargarTarifas();
  },
  methods: {
    async cargarTarifas() {
      this.loading = true;
      try {
        // Hacemos la solicitud para obtener las tarifas
        await obtenerTarifa().then((data) => {
          this.puerto_fijo = data.puerto_fijo || "";
          this.puerto_variable = data.puerto_variable || "";
          this.tarifa_atraque = data.tarifa_atraque || "";
          this.muellaje = data.muellaje || "";
          this.cuota_pasajero = data.cuota_pasajero || "";
          this.cuota_arribo_amarre = data.cuota_arribo_amarre || "";
          this.movimiento_interno = data.movimiento_interno || "";
          this.asitencia_practico = data.asitencia_practico || "";
        });

        // Asignamos los valores obtenidos a los campos
      } catch (error) {
        console.error("Error al cargar las tarifas", error);
      } finally {
        this.loading = false;
      }
    },
    async saveChanges() {
      this.loading = true;
      const datos = {
        puerto_fijo: this.puerto_fijo,
        puerto_variable: this.puerto_variable,
        tarifa_atraque: this.tarifa_atraque,
        muellaje: this.muellaje,
        cuota_pasajero: this.cuota_pasajero,
        cuota_arribo_amarre: this.cuota_arribo_amarre,
        movimiento_interno: this.movimiento_interno,
        muellaje: this.muellaje,
        asitencia_practico: this.asitencia_practico,
      };
      await guardarCambiosTarifas(datos)
        .then(() => {
          console.log("guardado");
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
