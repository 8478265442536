<template>
  <div>
    <loading-overlay :overlay="loading"></loading-overlay>

    <h1>Tickets {{ this.$route.params.estatus }}</h1>
    <v-layout class="mt-10">
      <v-row>
        <v-col cols="8">
          <material-card icon="confirmation_number" class="px-5 py-3">
            <v-data-table
              :headers="headers"
              :items="tickets"
              dense
              class="custom-table-header pointer-cursor mt-3"
              item-value="id"
              @click:row="handleRowClick"
            >
              <template v-slot:item.folio_ticket="{ item }">
                <td style="font-size: 12px; color: gray; font-weight: bold">
                  Ticket #{{ item.folio_ticket }}
                </td>
              </template>
              <template v-slot:item.asunto="{ item }">
                <td style="font-size: 12px; color: black; font-weight: bold">
                  {{ item.asunto }}
                </td>
              </template>
              <template v-slot:item.tema="{ item }">
                <td style="font-size: 12px; color: black; font-weight: bold">
                  {{ item.tema }}
                </td>
              </template>
              <template v-slot:item.prioridad="{ item }">
                <td style="font-size: 12px; color: black; font-weight: bold">
                  {{ item.prioridad }}
                </td>
              </template>
              <template v-slot:item.descripcion="{ item }">
                <td style="font-size: 12px; color: gray">
                  {{ item.descripcion }}
                </td>
              </template>
            </v-data-table>
          </material-card>
        </v-col>
        <v-col cols="4">
          <v-card class="pe-3">
            <v-card-title style="color: dimgrey">
              <h5 v-if="!ticket">Ningun ticket seleccionado</h5>
              <h5 v-else>Ticket #{{ ticket.folio_ticket }} - {{ ticket.asunto }}</h5>
            </v-card-title>
            <v-card-subtitle v-if="ticket">{{ ticket.tema }}</v-card-subtitle>
            <v-divider></v-divider>
            <v-timeline v-if="ticket" dense class="p-4">
              <v-timeline-item
                v-for="(item, index) in ticket.logs"
                :key="index"
                class="mb-4"
                :color="getStatusColor(item.estatus)"
                small
              >
                <v-row class="" justify="space-between">
                  <v-col cols="7">
                    <span style="font-size: 20px; font-weight: bold">
                      {{ item.titulo }}</span
                    >
                    <p style="font-size: 12px; color: gray">{{ item.descripcion }}</p>
                  </v-col>
                  <v-col class="text-right" cols="5">
                    <span style="font-size: 13px; color: gray">
                      {{ moment(item.fecha).calendar() }}
                    </span>
                  </v-col>
                </v-row>
              </v-timeline-item>
            </v-timeline>
          </v-card>
        </v-col>
      </v-row>
    </v-layout>
  </div>
</template>

<script>
import { getTicketsEstatus } from "../../api/endpoints";
import LoadingOverlay from "../components/Loading.vue";
import MaterialCard from "../components/MaterialCard.vue";
import moment from "moment";

export default {
  components: {
    LoadingOverlay,
    MaterialCard,
  },
  data() {
    return {
      headers: [
        { text: "Folio", value: "folio_ticket" },
        { text: "Descripción", value: "descripcion" },
        { text: "Asunto", value: "asunto" },
        { text: "Tema", value: "tema" },
        { text: "Prioridad", value: "prioridad" },
      ],
      loading: false,
      tickets: [],
      moment,
      ticket: null,
    };
  },
  created() {
    this.getTickets();
  },
  methods: {
    async getTickets() {
      this.loading = true;
      getTicketsEstatus(this.$route.params.estatus)
        .then((e) => {
          this.tickets = e;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getStatusColor(estatus) {
      switch (estatus) {
        case "En proceso":
          return "blue"; // Color para "En proceso"
        case "Resuelto":
          return "green"; // Color para "Resuelto"
        case "Cerrado":
          return "black"; // Color para "Cerrado"
        default:
          return "red"; // Color por defecto para estatus desconocidos
      }
    },
    handleRowClick(item) {
      this.ticket = item;
    },
  },
};
</script>

<style>
.pointer-cursor {
  cursor: pointer; /* Aplica el cursor de manito */
}
</style>
